import React, { useEffect, useState } from "react";
import "./CSS/MaintenanceScreen.css";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import Button from "@mui/material/Button";
import { pink, deepPurple } from "@mui/material/colors";
import { Alert, Snackbar } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@mui/material";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Box,
  Chip,
  Paper,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  FormControl, 
  InputLabel
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import StoreIcon from '@mui/icons-material/Store';
import LinkIcon from '@mui/icons-material/Link';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentsIcon from '@mui/icons-material/Payments';

import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar"; 
import { te } from "date-fns/locale";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        },
      },
    ],
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const MaintenanceScreen = () => {
  let status = "1";
  let path =
    status === "0" ? "http://localhost:3003" : "https://outlettee.com:3003";
 const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [userInfos, setUserInfos] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const response = await axios.get(path + "/api/usersAuthInfos"); // Fetch from the backend API
        console.log(response.data);
        const parsedData = response.data;
        setUserInfos(parsedData); // Set the fetched data to shopData state
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    fetchShopData();
  }, []);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenuClick = (menu) => {
    setSelectedMenu(menu); // Menü tıklamalarını yakala
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: "rgba(233, 30, 99, 0.08)",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const LightPurpleSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#5d4037", // Açık mor renk
      "&:hover": {
        backgroundColor: "rgba(205, 220, 57, 0.08)", // Hover rengi
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#795548", // Track rengi
    },
  }));
  const LimeGreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#cddc39", // Fıstık yeşili
      "&:hover": {
        backgroundColor: "rgba(205, 220, 57, 0.08)", // Hover rengi
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#cddc39", // Track rengi
    },
  }));
  const UserStatusTable = () => {
    const handleAuthChange = (userID, authType, currentStatus, caseNumber) => {
      // Durumu güncelle
      setUserInfos((prev) =>
        prev.map((user) =>
          user.UserID === userID
            ? { ...user, [authType]: currentStatus ? 0 : 1 }
            : user
        )
      );

      // Yeni durumu hesapla
      const newStatus = currentStatus ? 0 : 1;

      // Backend'e gönder
      UpdateAllAuthTypes(userID, newStatus, caseNumber);
    };

    return (
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "80%",
          maxHeight: "80vh",
          margin: "auto",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          borderRadius: "8px",
        }}
      >
        <Table
          sx={{
            width: "100%",
            "& th": {
              backgroundColor: "#405D72",
              color: "white",
              fontWeight: "bold",
              position: "sticky", // Başlığı sabitlemek için
              top: 0, // Sticky pozisyonunun başlangıç noktası
              zIndex: 2, // Diğer içeriklerin üstünde kalması için
            },
            "& td": {
              padding: "12px",
            },
            "& tr:nth-of-type(even)": {
              backgroundColor: "#f5f5f5",
            },
            "& tr:hover": {
              backgroundColor: "#e0f7fa",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Kullanıcı Adı</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Aktiflik Durumu</TableCell>
              <TableCell>Adminlik Durumu</TableCell>
              <TableCell>Dizayn Dönüştürme</TableCell>
              <TableCell>Dizayn Düzenleme</TableCell>
              <TableCell>Trademark</TableCell>
              <TableCell>Kullanıcı Efor</TableCell>
              <TableCell>Shop Efor</TableCell>
              <TableCell>Raporlar</TableCell>
              <TableCell>Rapor Düzenleme Yetkisi</TableCell>

              <TableCell style={{ display: "none" }}>UserID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userInfos.map((user) => (
              <TableRow key={user.UserID}>
                <TableCell>{user.Username}</TableCell>
                <TableCell>{user.Email}</TableCell>
                <TableCell>
                  <Switch
                    checked={user.Status === 1}
                    onChange={() =>
                      handleAuthChange(user.UserID, "Status", user.Status, "0")
                    }
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.isAdmin === 1}
                    onChange={() =>
                      handleAuthChange(
                        user.UserID,
                        "isAdmin",
                        user.isAdmin,
                        "1"
                      )
                    }
                    color="secondary"
                  />
                </TableCell>
                <TableCell>
                  <LightPurpleSwitch
                    checked={user.DesignConverter === 1}
                    onChange={() =>
                      handleAuthChange(
                        user.UserID,
                        "DesignConverter",
                        user.DesignConverter,
                        "2"
                      )
                    }
                    color="success"
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.DesignAdmin === 1}
                    onChange={() =>
                      handleAuthChange(
                        user.UserID,
                        "DesignAdmin",
                        user.DesignAdmin,
                        "3"
                      )
                    }
                    color="warning"
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.TradeMark === 1}
                    onChange={() =>
                      handleAuthChange(
                        user.UserID,
                        "TradeMark",
                        user.TradeMark,
                        "4"
                      )
                    }
                    color="error"
                  />
                </TableCell>
                <TableCell>
                  <LimeGreenSwitch
                    checked={user.UserEffort === 1}
                    onChange={() =>
                      handleAuthChange(
                        user.UserID,
                        "UserEffort",
                        user.UserEffort,
                        "5"
                      )
                    }
                    color="info"
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.ShopEffort === 1}
                    onChange={() =>
                      handleAuthChange(
                        user.UserID,
                        "ShopEffort",
                        user.ShopEffort,
                        "6"
                      )
                    }
                    color="success"
                  />
                </TableCell>
                <TableCell>
                  <PinkSwitch
                    checked={user.Reports === 1}
                    onChange={() =>
                      handleAuthChange(
                        user.UserID,
                        "Reports",
                        user.Reports,
                        "7"
                      )
                    }
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  <PinkSwitch
                    checked={user.ReportAdmin === 1}
                    onChange={() =>
                      handleAuthChange(
                        user.UserID,
                        "ReportAdmin",
                        user.ReportAdmin ,
                        "8"
                      )
                    }
                    color="primary"
                  />
                </TableCell>
                <TableCell style={{ display: "none" }}>{user.UserID}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const UpdateAllAuthTypes = async (UserID, Status, authType) => {
    try {
      const response = await axios.post(path + "/api/updateAuthType", {
        UserID,
        Status,
        authType,
      });

      // Eğer güncelleme başarılıysa:
      if (response.status === 200) {
        setAlertSeverity("success");
        setAlertMessage(response.data.message || "Güncelleme başarılı!");
        setAlertOpen(true);
      }
    } catch (error) {
      // Hata durumunda
      console.error("Error updating auth type:", error);
      setAlertSeverity("error");
      setAlertMessage(
        error.response?.data?.error || "Yetki güncellenirken bir hata oluştu!"
      );
      setAlertOpen(true);
    }
  };

  const [shopdata, setShopData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userShops, setUserShops] = useState({});
  console.log(shopdata);
  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const response = await axios.get(path + "/api/GetShopData"); // Fetch from the backend API

        const parsedData = response.data;
        setShopData(parsedData); // Set the fetched data to shopData state
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    fetchShopData();

    const fetchUserData = async () => {
      try {
        const response = await axios.get(path + "/api/GetUserData"); // Fetch from the backend API

        const parsedData = response.data;
        setUserData(parsedData); // Set the fetched data to shopData state
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (!selectedUser) return;

    // Backend'e istek: { UserID: selectedUser.UserID }
    const fetchUserShops = async () => {
      console.log(selectedUser.UserID);
      try {
        const response = await axios.post(path + "/api/getUserShops", {
          UserID: selectedUser.UserID,
        });

        const rows = response.data.data;
        if (Array.isArray(rows)) {
          // Aşağıda userShops'ı güncellemek için ShopID'leri çekiyoruz
          const shopIDs = rows.map((row) => row.ShopID);

          // userShops state'i => { [UserID]: [ShopIDs], ... }
          setUserShops((prev) => ({
            ...prev,
            [selectedUser.UserID]: shopIDs,
          }));
        }
      } catch (error) {
        console.error("Error fetching user shops:", error);
      }
    };

    fetchUserShops();
  }, [selectedUser]);

  const saveUserShops = async () => {
    if (!selectedUser) return;

    const selectedShopIds = userShops[selectedUser.UserID] || [];

    const payload = selectedShopIds.map((shopId) => {
      const shop = shopdata.find((s) => s.ID === shopId);

      return {
        ShopName: shop?.ShopName ?? "Unknown",
        ShopID: shop?.ID ?? 0,
        UserID: selectedUser.UserID,
        UserName: selectedUser.UserName,
        Status: true, // ya da kendi mantığınıza göre
      };
    });

    try {
      await axios.post(`${path}/api/saveUserShops`, payload);
      alert("Seçili kullanıcı mağazaları başarıyla kaydedildi!");
    } catch (error) {
      console.error("Save error:", error);
      alert("Kayıt sırasında hata oluştu.");
    }
  };

  const UserShopMatch = ({ userData, shopData }) => {
    const [searchUser, setSearchUser] = useState("");
    const [searchShop, setSearchShop] = useState("");

    const handleShopAssignment = (shopId) => {
      if (!selectedUser) return;

      setUserShops((prev) => ({
        ...prev,
        [selectedUser.UserID]: prev[selectedUser.UserID]
          ? prev[selectedUser.UserID].includes(shopId)
            ? prev[selectedUser.UserID].filter((id) => id !== shopId)
            : [...prev[selectedUser.UserID], shopId]
          : [shopId],
      }));
    };

    // Kullanıcıları filtreliyoruz
    const filteredUsers = userData.filter(
      (user) =>
        user.UserName.toLowerCase().includes(searchUser.toLowerCase()) ||
        user.Email.toLowerCase().includes(searchUser.toLowerCase())
    );

    // Mağazaları filtreliyoruz
    const filteredShops = shopData.filter((shop) =>
      shop.ShopName.toLowerCase().includes(searchShop.toLowerCase())
    );

    return (
      <Box
        sx={{
          p: 3,
          maxWidth: "90%",
          margin: "0 auto",
          maxHeight: "80vh",
          backgroundColor: "rgb(203, 214, 214)",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center", // Çok satırlı metinler için ortalama
            marginBottom: 2, // Alt boşluk (opsiyonel)
          }}
        >
          <Typography variant="h4" gutterBottom>
            Mağaza Yönetim Paneli
          </Typography>
        </Box>
        {/* 3 Card'ı yan yana göstermek için aynı Grid container içinde 3 Grid item kullanıyoruz */}
        <Grid container spacing={3}>
          {/* 1. Card: Kullanıcılar Listesi */}
          <Grid item xs={12} md={4}>
            <Card elevation={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                  p: 2,
                  overflow: "auto",
                  maxHeight: "80vh",
                }}
              >
                <Typography variant="h6" align="center">
                  Kullanıcılar
                </Typography>
                <TextField
                  size="small"
                  placeholder="Kullanıcı ara..."
                  value={searchUser}
                  onChange={(e) => setSearchUser(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%", maxWidth: "300px" }}
                />
              </Box>
              <CardContent
                sx={{
                  maxHeight: "500px", // Kart içeriğinin yüksekliğini sınırlayın
                  overflowY: "auto", // Dikey kaydırma çubuğu
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {filteredUsers.map((user) => (
                    <Paper
                      key={user.UserID}
                      elevation={1}
                      sx={{
                        p: 2,
                        cursor: "pointer",
                        bgcolor:
                          selectedUser?.UserID === user.UserID
                            ? "primary.light"
                            : "background.paper",
                        "&:hover": {
                          bgcolor:
                            selectedUser?.UserID === user.UserID
                              ? "primary.light"
                              : "action.hover",
                        },
                      }}
                      onClick={() => setSelectedUser(user)}
                    >
                      <Typography variant="subtitle1">
                        {user.UserName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {user.Email}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* 2. Card: Mağazalar Listesi */}
          <Grid item xs={12} md={4}>
            <Card elevation={3} sx={{ height: "100%", overflow: "hidden" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                  p: 2, // Başlık ve arama çubuğu için iç boşluk
                }}
              >
                <Typography variant="h6" align="center">
                  Mağazalar
                </Typography>
                <TextField
                  size="small"
                  placeholder="Mağaza ara..."
                  value={searchShop}
                  onChange={(e) => setSearchShop(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%", maxWidth: "300px" }}
                />
              </Box>
              <CardContent
                sx={{
                  maxHeight: "500px", // Kart içeriğinin yüksekliğini sınırlayın
                  overflowY: "auto", // Dikey kaydırma çubuğu
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {filteredShops.map((shop) => (
                    <Paper
                      key={shop.ID}
                      elevation={1}
                      sx={{
                        p: 2,
                        cursor: "pointer",
                        bgcolor:
                          selectedUser &&
                          userShops[selectedUser.UserID]?.includes(shop.ID)
                            ? "success.light"
                            : "background.paper",
                        "&:hover": {
                          bgcolor:
                            selectedUser &&
                            userShops[selectedUser.UserID]?.includes(shop.ID)
                              ? "success.light"
                              : "action.hover",
                        },
                      }}
                      onClick={() => handleShopAssignment(shop.ID)}
                    >
                      <Typography variant="subtitle1">
                        {shop.ShopName}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* 3. Card: Seçili Kullanıcı Bilgisi (Sadece bir kullanıcı seçiliyse) */}
          {selectedUser && (
            <Grid item xs={12} md={4}>
              <Card elevation={3} sx={{ height: "100%" }}>
                <CardHeader title="Seçili Kullanıcı Mağazaları" />
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    {selectedUser.UserName}
                  </Typography>
                  {/* Mağaza Chiplerini dikey göstermek için flexDirection: 'column' */}
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    {userShops[selectedUser.UserID]?.map((shopId) => {
                      const shop = shopData.find((s) => s.ID === shopId);
                      return (
                        <Chip
                          key={shopId}
                          label={shop?.ShopName}
                          color="primary"
                          sx={{ borderRadius: 1 }}
                        />
                      );
                    })}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center", // Çok satırlı metinler için ortalama
            marginTop: 2, // Alt boşluk (opsiyonel)
          }}
        >
          <Button variant="contained" onClick={saveUserShops}>
            Yetki Kaydet
          </Button>
        </Box>
      </Box>
    );
  };

  const UserHierarchy = ({ userData }) => {
    const [searchManager, setSearchManager] = useState("");
    const [searchSubordinate, setSearchSubordinate] = useState("");
    const [selectedManager, setSelectedManager] = useState(null);
    const [userHierarchy, setUserHierarchy] = useState({});

    useEffect(() => {
      const fetchUserSubordinates = async () => {
        if (!selectedManager) return;

        try {
          const response = await axios.post(`${path}/api/getUserUsers`, {
            UserID: selectedManager.UserID,
          });

          const rows = response.data.data;
          if (Array.isArray(rows)) {
            // Çalışanların UserID'lerini alıyoruz
            const subordinateUserIds = rows.map((row) => row.StaffUserID);

            // userHierarchy state'ini güncelliyoruz
            setUserHierarchy((prev) => ({
              ...prev,
              [selectedManager.UserID]: subordinateUserIds,
            }));
          }
        } catch (error) {
          console.error("Error fetching user subordinates:", error);
        }
      };

      fetchUserSubordinates();
    }, [selectedManager, path]);

    const handleUserAssignment = (subordinateId) => {
      if (!selectedManager) return;

      setUserHierarchy((prev) => ({
        ...prev,
        [selectedManager.UserID]: prev[selectedManager.UserID]
          ? prev[selectedManager.UserID].includes(subordinateId)
            ? prev[selectedManager.UserID].filter((id) => id !== subordinateId)
            : [...prev[selectedManager.UserID], subordinateId]
          : [subordinateId],
      }));
    };

    const filteredManagers = userData.filter(
      (user) =>
        user.UserName.toLowerCase().includes(searchManager.toLowerCase()) ||
        user.Email.toLowerCase().includes(searchManager.toLowerCase())
    );

    const filteredSubordinates = userData.filter(
      (user) =>
        user.UserName.toLowerCase().includes(searchSubordinate.toLowerCase()) ||
        user.Email.toLowerCase().includes(searchSubordinate.toLowerCase())
    );

    const saveUserHierarchy = async () => {
      console.log(selectedManager);
      if (!selectedManager) return;

      const selectedSubordinateIds =
        userHierarchy[selectedManager.UserID] || [];

      const payload = selectedSubordinateIds.map((subordinateId) => {
        const subordinate = userData.find((u) => u.UserID === subordinateId);

        return {
          StaffUserName: subordinate?.UserName ?? "Unknown",
          StaffUserID: subordinate?.UserID ?? 0,
          CaptainUserID: selectedManager.UserID,
          CaptainUserName: selectedManager.UserName,
          Status: true,
        };
      });

      console.log(payload);

      try {
        await axios.post(`${path}/api/saveUserHierarchy`, payload);
        alert("Yönetici-çalışan ilişkileri başarıyla kaydedildi!");
      } catch (error) {
        console.error("Save error:", error);
        alert("Kayıt sırasında hata oluştu.");
      }
    };

    return (
      <Box
        sx={{
          p: 3,
          maxWidth: "90%",
          margin: "0 auto",
          maxHeight: "80vh",
          backgroundColor: "rgb(203, 214, 214)",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginBottom: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Kullanıcı Yönetim Paneli
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {/* Managers List */}
          <Grid item xs={12} md={4}>
            <Card elevation={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                  p: 2,
                  overflow: "auto",
                  maxHeight: "80vh",
                }}
              >
                <Typography variant="h6" align="center">
                  Yöneticiler
                </Typography>
                <TextField
                  size="small"
                  placeholder="Yönetici ara..."
                  value={searchManager}
                  onChange={(e) => setSearchManager(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%", maxWidth: "300px" }}
                />
              </Box>
              <CardContent
                sx={{
                  maxHeight: "500px",
                  overflowY: "auto",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {filteredManagers.map((user) => (
                    <Paper
                      key={user.UserID}
                      elevation={1}
                      sx={{
                        p: 2,
                        cursor: "pointer",
                        bgcolor:
                          selectedManager?.UserID === user.UserID
                            ? "primary.light"
                            : "background.paper",
                        "&:hover": {
                          bgcolor:
                            selectedManager?.UserID === user.UserID
                              ? "primary.light"
                              : "action.hover",
                        },
                      }}
                      onClick={() => setSelectedManager(user)}
                    >
                      <Typography variant="subtitle1">
                        {user.UserName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {user.Email}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Subordinates List */}
          <Grid item xs={12} md={4}>
            <Card elevation={3} sx={{ height: "100%", overflow: "hidden" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                  p: 2,
                }}
              >
                <Typography variant="h6" align="center">
                  Çalışanlar
                </Typography>
                <TextField
                  size="small"
                  placeholder="Çalışan ara..."
                  value={searchSubordinate}
                  onChange={(e) => setSearchSubordinate(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%", maxWidth: "300px" }}
                />
              </Box>
              <CardContent
                sx={{
                  maxHeight: "500px",
                  overflowY: "auto",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {filteredSubordinates.map((user) => (
                    <Paper
                      key={user.UserID}
                      elevation={1}
                      sx={{
                        p: 2,
                        cursor: "pointer",
                        bgcolor:
                          selectedManager &&
                          userHierarchy[selectedManager.UserID]?.includes(
                            user.UserID
                          )
                            ? "success.light"
                            : "background.paper",
                        "&:hover": {
                          bgcolor:
                            selectedManager &&
                            userHierarchy[selectedManager.UserID]?.includes(
                              user.UserID
                            )
                              ? "success.light"
                              : "action.hover",
                        },
                      }}
                      onClick={() => handleUserAssignment(user.UserID)}
                    >
                      <Typography variant="subtitle1">
                        {user.UserName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {user.Email}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Selected Manager's Subordinates */}
          {selectedManager && (
            <Grid item xs={12} md={4}>
              <Card elevation={3} sx={{ height: "100%" }}>
                <CardHeader title="Seçili Yöneticinin Çalışanları" />
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    {selectedManager.UserName}
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    {userHierarchy[selectedManager.UserID]?.map((userId) => {
                      const user = userData.find((u) => u.UserID === userId);
                      return (
                        <Chip
                          key={userId}
                          label={user?.UserName}
                          color="primary"
                          sx={{ borderRadius: 1 }}
                        />
                      );
                    })}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: 2,
          }}
        >
          <Button variant="contained" onClick={saveUserHierarchy}>
            Yetkileri Kaydet
          </Button>
        </Box>
      </Box>
    );
  };

  const UserEffortManagement = () => {
    const [loading, setLoading] = useState(false);
    const effortTypes = ["Shop", "ARGE"];

    const [userEfforts, setUserEfforts] = useState(
      userData.map((user) => ({
        userId: user.UserID,
        userName: user.UserName,
        effortType1: "",
        effortType1Fee: "",
        effortType2: "",
        effortType2Fee: "",
      }))
    );

    useEffect(() => {
      const fetchShopData = async () => {
        setLoading(true);
        try {
          const response = await axios.get(path + "/api/AllEffortsFees");
          const parsedData = response.data;

          if (parsedData && parsedData.length > 0) {
            const updatedEfforts = userData.map((user) => {
              const existingEffort = parsedData.find(
                (effort) => effort.userId === user.UserID
              );

              return {
                userId: user.UserID,
                userName: user.UserName,
                effortType1: existingEffort?.effortType1 || "",
                effortType1Fee: existingEffort?.effortType1Fee || "",
                effortType2: existingEffort?.effortType2 || "",
                effortType2Fee: existingEffort?.effortType2Fee || "",
              };
            });

            setUserEfforts(updatedEfforts);
          }
        } catch (error) {
          console.error("Error fetching shop data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchShopData();
    }, []);

    const handleChange = (userId, field, value) => {
      setUserEfforts((prevEfforts) =>
        prevEfforts.map((effort) =>
          effort.userId === userId ? { ...effort, [field]: value } : effort
        )
      );
    };

    const handleSubmit = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          path + "/api/effortsFees",
          userEfforts,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setAlertSeverity("success");
          setAlertMessage("Veriler başarılı bir şekilde kaydedildi.");
          setAlertOpen(true);
        } else {
          throw new Error("Kayıt sırasında bir hata oluştu");
        }
      } catch (error) {
        console.error("Hata:", error);
        setAlertSeverity("error");
        setAlertMessage(
          "Bir hata oluştu: " + (error.response?.data?.message || error.message)
        );
        setAlertOpen(true);
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            flexDirection: "column",
            gap: 2,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" size={60} />
          <Typography variant="h6">
            İşlem yapılıyor, lütfen bekleyiniz...
          </Typography>
        </Backdrop>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            bgcolor: "rgb(180, 190, 190)",
            p: 2,
          }}
        >
          <Box
            sx={{
              overflowY: "auto",
              mx: "auto",
              width: "100%",
              p: 2,
              boxSizing: "border-box",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <TableContainer component={Paper} style={{ width: "50%" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Kullanıcı</TableCell>
                    <TableCell>Effort Tipi 1</TableCell>
                    <TableCell style={{ width: "20%" }}>
                      Saatlik Ücret 1
                    </TableCell>
                    <TableCell>Effort Tipi 2</TableCell>
                    <TableCell style={{ width: "20%" }}>
                      Saatlik Ücret 2
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData.map((user) => {
                    const userEffort = userEfforts.find(
                      (e) => e.userId === user.UserID
                    ) || {
                      effortType1: "",
                      effortType1Fee: "",
                      effortType2: "",
                      effortType2Fee: "",
                    };

                    return (
                      <TableRow key={user.UserID}>
                        <TableCell>{user.UserName}</TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            value={userEffort.effortType1}
                            onChange={(e) =>
                              handleChange(
                                user.UserID,
                                "effortType1",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value="">
                              <em>Seçiniz</em>
                            </MenuItem>
                            {effortTypes.map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            fullWidth
                            value={userEffort.effortType1Fee}
                            onChange={(e) =>
                              handleChange(
                                user.UserID,
                                "effortType1Fee",
                                e.target.value
                              )
                            }
                            InputProps={{
                              startAdornment: "$",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            value={userEffort.effortType2}
                            onChange={(e) =>
                              handleChange(
                                user.UserID,
                                "effortType2",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value="">
                              <em>Seçiniz</em>
                            </MenuItem>
                            {effortTypes.map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            fullWidth
                            value={userEffort.effortType2Fee}
                            onChange={(e) =>
                              handleChange(
                                user.UserID,
                                "effortType2Fee",
                                e.target.value
                              )
                            }
                            InputProps={{
                              startAdornment: "$",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="mt-4 flex justify-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Kaydediliyor..." : "Kaydet"}
              </Button>
            </div>
          </Box>
        </Box>
      </>
    );
  };

  const ShopExpenses = () => {

    const [alertOpenShop, setAlertOpenShop] = useState(false);
    const [alertMessageShop, setAlertMessageShop] = useState("");
    const [alertSeverityShop, setAlertSeverityShop] = useState("success");
    const [expenses, setExpenses] = useState([]);
    const [selectedExpense, setSelectedExpense] = useState({
      id: null,
      shop: "",
      expenseType: "",
      startDate: null,
      endDate: null,
      amount: "",
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const shops = shopdata.map((shop) => shop.ShopName);
    const expenseTypes = ["Shop", "Personel"];
  
    useEffect(() => {
      fetchExpenses();
    }, []);
  
    const fetchExpenses = async () => {
      try {
        const response = await axios.get(path + '/api/expenses');
        // Tarih değerlerini dayjs nesnesine dönüştür
        const formattedExpenses = response.data.map((expense) => ({
          ...expense,
          startDate: expense.startDate ? dayjs(expense.startDate) : null,
          endDate: expense.endDate ? dayjs(expense.endDate) : null,
        }));
        setExpenses(formattedExpenses);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching expenses:', error);
        setError('Failed to fetch expenses');
        setLoading(false);
      }
    };
  
    const handleInputChange = (field, value) => {
      setSelectedExpense((prev) => ({ ...prev, [field]: value }));
    };
  
    const handleAddOrUpdateExpense = async () => {
      try {
        if (selectedExpense.id) {
          // Güncelleme işlemi
          await axios.put(`${path}/api/expenses/${selectedExpense.id}`, selectedExpense);
        } else {
          // Ekleme işlemi
          await axios.post(path + '/api/expenses', selectedExpense);
        }
        setAlertSeverityShop("success");
        setAlertMessageShop("İşlem başarılı!");
        setAlertOpenShop(true);
        fetchExpenses();
        setSelectedExpense({
          id: null,
          shop: "",
          expenseType: "",
          startDate: null,
          endDate: null,
          amount: "",
        });
      } catch (error) {
        console.error('Error saving expense:', error);
       
        setAlertSeverityShop("error");
        setAlertMessageShop("İşlem sırasında bir hata oluştu!");
        setAlertOpenShop(true);
      }
    };
  
    const handleDeleteExpense = async (id) => {
      try {
        await axios.delete(`${path}/api/expenses/${id}`);
       
        setAlertSeverityShop("success");
        setAlertMessageShop("Gider silindi!");
        setAlertOpenShop(true);
        fetchExpenses();
      } catch (error) {
        console.error('Error deleting expense:', error);
       
        setAlertSeverityShop("error");
        setAlertMessageShop("Gider silinirken bir hata oluştu!" + error);
        setAlertOpenShop(true);
      }
    };
  
    const handleRowClick = (expense) => {
      // Tarih değerlerini dayjs nesnesine dönüştür
      setSelectedExpense({
        ...expense,
        startDate: expense.startDate ? dayjs(expense.startDate) : null,
        endDate: expense.endDate ? dayjs(expense.endDate) : null,
      });
    };
  
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
  
    return (
      <>
      <Snackbar
        open={alertOpenShop}
        autoHideDuration={6000}
        onClose={() => setAlertOpenShop(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setAlertOpenShop(false)} severity={alertSeverityShop}>
          {alertMessageShop}
        </Alert>
      </Snackbar>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 4,
            mt: 4,
            p: 2,
            minHeight: "100vh",
          }}
        >
          {/* Input Alanları */}
          <Paper elevation={3} sx={{ p: 4, bgcolor: 'white', width: '80%', borderRadius: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center' }}>
              <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold', mb: 2 }}>
                Shop Diğer Ücretler Tanımlama Ekranı
              </Typography>
  
              {/* Shop and Expense Type Selects */}
              <Box sx={{ display: 'flex', gap: 3, width: '100%', justifyContent: 'center' }}>
                <Select
                  label="Shop"
                  value={selectedExpense.shop}
                  onChange={(e) => handleInputChange('shop', e.target.value)}
                  fullWidth
                  sx={{ maxWidth: 260 }}
                >
                  <MenuItem value="">
                    <em>Select Shop</em>
                  </MenuItem>
                  {shops.map((shop) => (
                    <MenuItem key={shop} value={shop}>
                      {shop}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  label="Expense Type"
                  value={selectedExpense.expenseType}
                  onChange={(e) => handleInputChange('expenseType', e.target.value)}
                  fullWidth
                  sx={{ maxWidth: 260 }}
                >
                  <MenuItem value="">
                    <em>Select Expense Type</em>
                  </MenuItem>
                  {expenseTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
  
              {/* Date Pickers */}
              <Box sx={{ display: 'flex', gap: 3, width: '100%', justifyContent: 'center' }}>
                <DatePicker
                  label="Start Date"
                  value={selectedExpense.startDate}
                  onChange={(newValue) => handleInputChange('startDate', newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth sx={{ maxWidth: 250 }} />}
                />
                <DatePicker
                  label="End Date"
                  value={selectedExpense.endDate}
                  onChange={(newValue) => handleInputChange('endDate', newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth sx={{ maxWidth: 250 }} />}
                />
              </Box>
  
              {/* Amount Input */}
              <TextField
                label="Amount"
                type="number"
                value={selectedExpense.amount}
                onChange={(e) => handleInputChange('amount', e.target.value)}
                fullWidth
                sx={{ maxWidth: 300 }}
                InputProps={{
                  startAdornment: <Box component="span" sx={{ mr: 1 }}>$</Box>,
                }}
              />
  
              {/* Buttons */}
              <Box sx={{ display: 'flex', gap: 0, mt: 0 }}>
                <Button variant="contained" color="primary" onClick={handleAddOrUpdateExpense} sx={{ px: 4 ,marginRight:5}}>
                  {selectedExpense.id ? 'Update' : 'Add'}
                </Button>
                {selectedExpense.id && (
                  <Button variant="contained" color="secondary" onClick={() => handleDeleteExpense(selectedExpense.id)} sx={{ px: 4 }}>
                    Delete
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
  
          {/* Tablo */}
          <TableContainer component={Paper} sx={{ width: "80%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Shop</TableCell>
                  <TableCell>Expense Type</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Amount ($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expenses.map((expense) => (
                  <TableRow key={expense.id} onClick={() => handleRowClick(expense)}>
                    <TableCell>{expense.shop}</TableCell>
                    <TableCell>{expense.expenseType}</TableCell>
                    <TableCell>{expense.startDate ? expense.startDate.format('YYYY-MM-DD') : ''}</TableCell>
                    <TableCell>{expense.endDate ? expense.endDate.format('YYYY-MM-DD') : ''}</TableCell>
                    <TableCell>${expense.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </LocalizationProvider>
      </>
    );
  };

  const ScreenShotAuth = () => {
    const [screenShotAuthData, setScreenShotAuthData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [previousAuths, setPreviousAuths] = useState([]);
    const [userData, setUserData] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const [screenshotResponse, userDataResponse, previousAuthResponse] = await Promise.all([
            axios.get(`${path}/api/GetSecreenShotsUsers`),
            axios.get(`${path}/api/GetUserData`),
            axios.get(`${path}/api/GetPreviousScreenShotAuth`)
          ]);
  
          const screenshotData = screenshotResponse.data;
          const userData = userDataResponse.data;
          const previousAuthData = previousAuthResponse.data;
  
          // Önceki eşleşmeleri dikkate alarak initial state'i oluştur
          const initialSelectedUsers = screenshotData.reduce((acc, item) => {
            // Eğer daha önce eşleşmiş ise o eşleşmeyi kullan
            const matchedAuth = previousAuthData.find(
              auth => auth.LocalIP === item.LocalIP && auth.HostName === item.HostName
            );
            
            // Eğer eşleşen bir kullanıcı varsa, UserID'yi seç
            acc[item.LocalIP] = matchedAuth 
              ? matchedAuth.SystemUserID 
              : (
                // Kullanıcı adı benzerliğine göre otomatik eşleştirme
                userData.find(user => 
                  user.UserName.toLowerCase() === item.UserName.toLowerCase()
                )?.UserID || ''
              );
            
            return acc;
          }, {});
  
          setScreenShotAuthData(screenshotData);
          setUserData(userData);
          setPreviousAuths(previousAuthData);
          setSelectedUsers(initialSelectedUsers);
  
        } catch (error) {
          console.error("Error fetching data:", error);
          handleSnackbar('Veri yükleme hatası', 'error');
        }
      };
  
      fetchData();
    }, []);
  
  
    const handleUserChange = (localIp, userId) => {
      setSelectedUsers(prev => ({
        ...prev,
        [localIp]: userId
      }));
    };
  
    const handleSnackbar = (message, severity = 'success') => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setOpenSnackbar(true);
    };
  
    const handleCloseSnackbar = () => {
      setOpenSnackbar(false);
    };
  
    const handleSave = async () => {
      try {
        // Sadece seçim yapılmış ve daha önce eşleşmemiş kayıtları filtrele
        const authData = screenShotAuthData.filter(item => 
          selectedUsers[item.LocalIP] && 
          !previousAuths.some(
            auth => 
              auth.LocalIP === item.LocalIP && 
              auth.HostName === item.HostName && 
              auth.SystemUserID === selectedUsers[item.LocalIP]
          )
        ).map(item => {
          const selectedUserId = selectedUsers[item.LocalIP];
          const selectedUser = userData.find(user => user.UserID === selectedUserId);
          
          return {
            LocalIP: item.LocalIP,
            HostName: item.HostName,
            UserName: item.UserName,
            SystemUserID: selectedUserId,
            SystemUserName: selectedUser ? selectedUser.UserName : null
          };
        });
  
        // Eğer yeni kayıt yoksa işlem yapma
        if (authData.length === 0) {
          handleSnackbar('Kaydetilecek yeni bir eşleşme yok', 'info');
          return;
        }
  
        const response = await axios.post(`${path}/api/SaveScreenShotAuth`, authData);
        
        handleSnackbar('Kayıt başarıyla gerçekleştirildi');
        
        // Yeni eklenen kayıtları previousAuths'a ekle
        setPreviousAuths([...previousAuths, ...authData]);
  
      } catch (error) {
        console.error("Kaydetme hatası:", error);
        handleSnackbar('Kaydetme sırasında bir hata oluştu', 'error');
      }
    };
    return (
      <>
       <Snackbar 
          open={openSnackbar} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity={snackbarSeverity} 
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

   <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Local IP</TableCell>
              <TableCell>Hostname</TableCell>
              <TableCell>Current Username</TableCell>
              <TableCell>Assign User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {screenShotAuthData.map((item) => (
              <TableRow key={item.LocalIP}>
                <TableCell>{item.LocalIP}</TableCell>
                <TableCell>{item.HostName}</TableCell>
                <TableCell>{item.UserName}</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel>Select User</InputLabel>
                    <Select
                      value={selectedUsers[item.LocalIP] || ''}
                      label="Select User"
                      onChange={(e) => handleUserChange(item.LocalIP, e.target.value)}
                    >
                      {userData.map((user) => (
                        <MenuItem key={user.UserID} value={user.UserID}>
                          {user.UserName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSave}
          style={{ marginTop: 16 }}
        >
          Kaydet
        </Button>
  
        
      </>
    );
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // Artık sabit yükseklik kullanmıyoruz.
          // height: "100vh",
          minHeight: "100vh", // sayfa boyu kadar arkaplanın uzaması için
          bgcolor: "rgb(180, 190, 190)",
          p: 2,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            style={{ backgroundColor: "#405D72" }}
          >
            <Toolbar style={{ backgroundColor: "#405D72" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={[
                  {
                    mr: 2,
                  },
                  open && { display: "none" },
                ]}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Bakım Ekranları
              </Typography>
              <IconButton
  onClick={() => navigate("/")}
  sx={{
    display: "flex",  // İçindeki elemanları yan yana hizalar
    alignItems: "center",  // Dikey hizalamayı ortalar
    gap: 1,  // Avatar ile metin arasına boşluk ekler
    padding: "5px 15px", // İç boşluk ayarı
    borderRadius: "25px", // Oval buton şekli
    backgroundColor: "#fff",
    ml: "auto", // En sağa yaslamak için
    "&:hover": {
      backgroundColor: "#ccc",
    },
  }}
>
  <Avatar
    src="/Images/Shops/Nile/DesignCluster1/Design4/Design4.svg"
    alt="Ana Menü"
    sx={{ width: 50, height: 50 }}
  />
  <Typography
    variant="h6"
    sx={{
      fontWeight: "bold",  // Kalın yazı tipi
      color: "#000",  // Siyah renk
    }}
  >
    Ana Menü
  </Typography>
</IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                backgroundColor: "#DDE7F2",
              },
            }}
            variant="persistent"
            anchor="left"
            open={true}
          >
            <Toolbar>
              <IconButton onClick={handleDrawerClose}>
                {<ChevronLeftIcon />}
              </IconButton>
            </Toolbar>
            <Divider />
            <List>
              {[
                { text: "Kullanıcı Yetkileri", icon: <SecurityIcon /> },
                { text: "Kullanıcı Shop Eşleştirme", icon: <StoreIcon /> },
                {
                  text: "Kullanıcı Hiyerarşi Tanımlama Ekranı",
                  icon: <AccountTreeIcon />,
                },
                {
                  text: "Kullanıcı Ücret Tanımlama Ekranı",
                  icon: <AttachMoneyIcon />,
                },
                {
                  text: "Diğer Ücretler Tanımlama Ekranı",
                  icon: <ReceiptIcon />,
                },
                {text: "Sunucu Kullanıcı Eşleştirme", icon: <ReceiptIcon />},
              ].map(({ text, icon }, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton onClick={() => handleMenuClick(text)}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3, maxHeight: "100vh" }}>
            <Toolbar />
            {/* Menüye göre dinamik içerik */}
            {selectedMenu === "Kullanıcı Yetkileri" && (
              <Typography>
                <UserStatusTable />
              </Typography>
            )}
            {selectedMenu === "Kullanıcı Shop Eşleştirme" && (
              <Typography>
                <UserShopMatch userData={userData} shopData={shopdata} />
              </Typography>
            )}
            {/* Diğer menü öğelerine içerik eklenebilir */}
            {selectedMenu === "Kullanıcı Hiyerarşi Tanımlama Ekranı" && (
              <Typography>
                <UserHierarchy userData={userData} />
              </Typography>
            )}
            {selectedMenu === "Kullanıcı Ücret Tanımlama Ekranı" && (
              <Typography>
                <UserEffortManagement />
              </Typography>
            )}
            {selectedMenu === "Diğer Ücretler Tanımlama Ekranı" && (
              <Typography>
                <ShopExpenses />
              </Typography>
            )}
            {selectedMenu === "Sunucu Kullanıcı Eşleştirme" && (
              <Typography>
                <ScreenShotAuth />
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MaintenanceScreen;
