import React, { useEffect, useState, useCallback } from "react";
import "./CSS/UserEffort.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  CircularProgress,
  Backdrop,
  TextField,
  Checkbox,
  TextareaAutosize,
} from "@mui/material";
import axios from "axios";
import { Alert, AlertTitle } from "@mui/material";
import {
  Typography,
  Paper,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useUser } from "./Context/UserContext";
import { Snackbar } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Card,
  CardContent,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { isSameDay, set } from "date-fns";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FileUpload } from "primereact/fileupload";
import "primereact/resources/themes/saga-blue/theme.css"; // Tema CSS
import "primereact/resources/primereact.min.css"; // PrimeReact bileşen stilleri
import "primeicons/primeicons.css";
import { Shop } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TableChartIcon from "@mui/icons-material/TableChart";
import Avatar from "@mui/material/Avatar"; 
import { useNavigate } from "react-router-dom";
import { User } from "lucide-react";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: "800px",
  width: "100%",
  margin: "0 auto",
  backgroundColor: "#f5f5f5",
  marginTop: theme.spacing(2),
  boxShadow: theme.shadows[3],
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: "100%",
}));

const MoneyTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputAdornment-root": {
    marginRight: theme.spacing(1),
  },
}));

const ShopEffort = () => {
  let status = "1";
  let path =
    status === "0" ? "http://localhost:3003" : "https://outlettee.com:3003";
      const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [filterDate, setFilterDate] = useState(dayjs());
  const [filterEndDate, setEndFilterDate] = useState(dayjs());
  const [filterUpdateDate, setFilterUpdateDate] = useState(dayjs());
  const [filterEndUpdateDate, setEndFilterUpdateDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const { user } = useUser();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const today = new Date();

  const calculateTimeDifference = () => {
    if (startTime && endTime) {
      const start = dayjs(startTime);
      const end = dayjs(endTime);
      return end.diff(start, "minute"); // Dakika farkı
    }
    return null;
  };
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    console.log(user);
    const fetchShopData = async () => {
      try {
        const response = await axios.post(path + "/api/GetShopFeesData", {
          UserID: user?.ID,
          UserIsAdmin: user?.ReportAdmin,
          date: filterDate.format("YYYY-MM-DD"),
        });

        const groupedData = response.data.reduce((acc, row) => {
          if (!acc[row.ID]) {
            acc[row.ID] = {
              ...row,
              details: [],
            };
          }
          acc[row.ID].details.push({
            EtsyID: row.EtsyID,
            PaymentDate: row.PaymentDate,
            PaymentFee: row.PaymentFee,
          });
          return acc;
        }, {});

        setTableData(Object.values(groupedData));
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    fetchShopData();
  }, [filterDate]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchShopAuth = async () => {
      try {
        const response = await axios.post(path + "/api/getUserShops", {
          UserID: user?.ID,
          UserIsAdmin: user?.ReportAdmin,
        });
        const options = response.data.data.map((shop) => ({
          value: shop.ShopName,
          label: shop.ShopName,
        }));
        setOptions(options);
        console.log(options);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };
    fetchShopAuth();
  }, [user, path]);

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [selectedUpdateValue, setSelectedUpdateValue] = useState("");

  const handleUpdateChange = (event) => {
    setSelectedUpdateValue(event.target.value);
  };

  const [files, setFiles] = useState([]);

  const onUpload = (event) => {
    const uploadedFiles = event.files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    if (files.length + uploadedFiles.length > 3) {
      alert("En fazla 3 dosya yükleyebilirsiniz!");
      return;
    }
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };

  const removeFile = (fileToRemove) => {
    setFiles((files) => files.filter((file) => file !== fileToRemove));
    URL.revokeObjectURL(fileToRemove.preview);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });

  const handleBlur = (event) => {
    const { name, value } = event.target;
    const formattedValue = formatNumber(value);
    setFormData((prev) => ({
      ...prev,
      [name]: formattedValue, // Güncellenen değeri ekle
    }));
  };

  const handleBlurUpdate = (event) => {
    const { name, value } = event.target;
    const formattedValue = formatNumber(value);
    setUpdateFormValues((prev) => ({
      ...prev,
      [name]: formattedValue, // Güncellenen değeri ekle
    }));
  };

  const [rows, setRows] = useState([{ id: 1, date: "", amount: "" }]);
  const [rowsUpdate, setRowsUpdate] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.name;

    const data = new FormData(event.target);

    // Extracting form data
    const selectedShop = data.get("SelelectedShop");
    const startDate = data.get("startDate");
    const endDate = data.get("endDate");
    const salesSummary =
      data.get("salesSummary")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const totalSalesAmount =
      data.get("totalSalesAmount")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const totalSales =
      data.get("totalSales")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const totalRefundsAmounts =
      data.get("totalRefundsAmounts")?.replace(/\./g, "").replace(/,/g, ".") ||
      "";
    const totalRefunds =
      -data.get("totalRefunds")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const listingFees =
      -data.get("listingFees")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const marketingFees =
      -data.get("marketingFees")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const ShippingFees =
      -data.get("ShippingFees")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const CSVSalesSummary =
      data.get("CSVSalesSummary")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const filterStartDateCSV = data.get("startDateCSV");
    const filterEndDateCSV = data.get("endDateCSV");
    const productTotalSum =
      -data.get("productTotalSum")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const productCustomCost =
      -data.get("productCustomCost")?.replace(/\./g, "").replace(/,/g, ".") ||
      "";
    const returnAndExchange =
      data.get("returnAndExchange")?.replace(/\./g, "").replace(/,/g, ".") ||
      "";
    const ShippingFeesCustomHub =
      data
        .get("ShippingFeesCustomHub")
        ?.replace(/\./g, "")
        .replace(/,/g, ".") || "0";
    const piaceInput = data.get("piaceInput")?.replace(/\./g, "");

    // User-related fields
    const UserID = user?.ID;
    const username = user?.Username;
    console.log(piaceInput);

    // Validations
    if (!selectedShop || !startDate || !endDate) {
      setAlertSeverity("error");
      setAlertMessage("Lütfen tüm zorunlu alanları doldurunuz!");
      setAlertOpen(true);
      return; // Stop submission
    }

    if (!UserID || !username) {
      setAlertSeverity("error");
      setAlertMessage(
        "Beklenmedik bir hata oluştu. Lütfen yöneticinizle iletişime geçiniz!"
      );
      setAlertOpen(true);
      return; // Stop submission
    }

    try {
      // Send the data to the backend
      const response = await axios.post(path + "/api/SaveShopFees", {
        UserID,
        username,
        selectedShop,
        startDate,
        endDate,
        salesSummary,
        totalSalesAmount,
        totalSales,
        totalRefundsAmounts,
        totalRefunds,
        listingFees,
        marketingFees,
        ShippingFees,
        CSVSalesSummary,
        filterStartDateCSV,
        filterEndDateCSV,
        productTotalSum,
        productCustomCost,
        returnAndExchange,
        piaceInput,
        rows,
        ShippingFeesCustomHub,
      });

      setAlertSeverity("success");
      setAlertMessage("Shop verisi başarıyla kaydedildi.");
      setAlertOpen(true);
      setTimeout(() => {
        event.target.reset();
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        setAlertSeverity("error");
        setAlertMessage(
          error.response.data.message || "Efor kaydedilirken bir hata oluştu."
        );
      } else {
        setAlertSeverity("error");
        setAlertMessage("Efor kaydedilirken bir hata oluştu: " + error.message);
      }
    
      setAlertOpen(true);
    }
  };

  const formatNumericValue = (value) => {
    // Eğer değer null, undefined veya boşsa, direkt döndür
    if (value == null || value === "") return "";

    // Sayıyı dönüştür ve binlik ayracı ile ondalık ayırıcı ekle
    return Number(value).toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2, 
    });
  };

  const handleUpdateSubmit = async (e) => {
    console.log(rowsUpdate);
    e.preventDefault();
    const buttonClicked = e.nativeEvent.submitter.name;
    console.log("Button clicked:", buttonClicked);

    const data = new FormData(e.target);

    const selectedShop = data.get("SelelectedShop");
    const startDate = data.get("startDate");
    const endDate = data.get("endDate");
    const salesSummary =
      data.get("salesSummary")?.replace(/\./g, "").replace(/,/g, ".") || "";
    console.log("salesSummary", salesSummary);
    const totalSalesAmount =
      data.get("totalSalesAmount")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const totalSales =
      data.get("totalSales")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const totalRefundsAmounts =
      data.get("totalRefundsAmounts")?.replace(/\./g, "").replace(/,/g, ".") ||
      "";
    const totalRefunds =
      data.get("totalRefunds")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const listingFees =
      data.get("listingFees")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const marketingFees =
      data.get("marketingFees")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const ShippingFees =
      data.get("ShippingFees")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const CSVSalesSummary =
      data.get("CSVSalesSummary")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const filterStartDateCSV = data.get("startDateCSV");
    const filterEndDateCSV = data.get("endDateCSV");
    const productTotalSum =
      data.get("productTotalSum")?.replace(/\./g, "").replace(/,/g, ".") || "";
    const productCustomCost =
      data.get("productCustomCost")?.replace(/\./g, "").replace(/,/g, ".") ||
      "";
    const returnAndExchange =
      -data.get("returnAndExchange")?.replace(/\./g, "").replace(/,/g, ".") ||
      "";
    const ShippingFeesCustomHub =
      data
        .get("ShippingFeesCustomHub")
        ?.replace(/\./g, "")
        .replace(/,/g, ".") || "";
    const piaceInput = data.get("piaceInput");
    // User-related fields
    const UserID = user?.ID;
    const username = user?.Username;
    const formID = formUpdateValues.indexID;
  
    if (!selectedShop || !startDate || !endDate) {
      setAlertSeverity("error");
      setAlertMessage("Lütfen tüm zorunlu alanları doldurunuz!");
      setAlertOpen(true);
      return;
    }

    if (!UserID || !username) {
      setAlertSeverity("error");
      setAlertMessage(
        "Beklenmedik bir hata oluştu. Lütfen yöneticinizle iletişime geçiniz!"
      );
      setAlertOpen(true);
      return; 
    }
    if (buttonClicked === "update") {
    try {
    
      const response = await axios.post(path + "/api/UpdateShopFees", {
        UserID,
        username,
        selectedShop,
        startDate,
        endDate,
        salesSummary,
        totalSalesAmount,
        totalSales,
        totalRefundsAmounts,
        totalRefunds,
        listingFees,
        marketingFees,
        ShippingFees,
        CSVSalesSummary,
        filterStartDateCSV,
        filterEndDateCSV,
        productTotalSum,
        productCustomCost,
        returnAndExchange,
        piaceInput,
        formID,
        rowsUpdate,
        ShippingFeesCustomHub,
      });

      setAlertSeverity("success");
      setAlertMessage("Shop verisi başarıyla güncellendi.");
      setAlertOpen(true);
      setTimeout(() => {
        e.target.reset();
        window.location.reload();
      }, 2000);
    } catch (error) {
      // Handle error
      setAlertSeverity("error");
      setAlertMessage("Efor güncellenirken bir hata oluştu.");
      setAlertOpen(true);
    }
    } else if (buttonClicked === "delete") {

      try {
        const response = await axios.post(path + "/api/DeleteShopFees", {
          formID: formID,
        });
    
        if (response.data.success) {
          setAlertSeverity("success");
          setAlertMessage("Shop verisi başarıyla siilindi.");
          setAlertOpen(true);
          setTimeout(() => {
            e.target.reset();
            window.location.reload();
          }, 2000);
        } else {
          setAlertSeverity("error");
          setAlertMessage("Silme işlemi başarısız: " + response.data.message);
          setAlertOpen(true);
        }
      } catch (error) {
        if (error.response) {
          setAlertSeverity("error");
          setAlertMessage(
            error.response.data.message || "Efor kaydedilirken bir hata oluştu."
          );
        } else {
          setAlertSeverity("error");
          setAlertMessage("Efor kaydedilirken bir hata oluştu: " + error.message);
        }
      
        setAlertOpen(true);
      }
    }
  };

  const [formData, setFormData] = React.useState({
    salesSummary: "",
    totalSalesAmount: "",
    totalSales: "",
    totalRefundsAmounts: "",
    totalRefunds: "",
    listingFees: "",
    marketingFees: "",
    ShippingFees: "",
    CSVSalesSummary: "",
    filterStartDateCSV: null,
    filterEndDateCSV: null,
    isCustomStartDate: false,
    isCustomEndDate: false,
    productTotalSum: "",
    productCustomCost: "",
    returnAndExchange: "",
    ShippingFeesCustomHub: "",
    piaceInput: "",
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [formUpdateValues, setUpdateFormValues] = useState({
    SelelectedShop: "",
    startDate: null,
    endDate: null,
    salesSummary: "",
    totalSalesAmount: "",
    totalSales: "",
    totalRefundsAmounts: "",
    totalRefunds: "",
    listingFees: "",
    marketingFees: "",
    ShippingFees: "",
    CSVSalesSummary: "",
    filterStartDateCSV: null,
    filterEndDateCSV: null,
    isCustomStartDate: false,
    isCustomEndDate: false,
    productTotalSum: "",
    productCustomCost: "",
    returnAndExchange: "",
    ShippingFeesCustomHub: "",
    piaceInput: "",
    indexID: null,
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChanges = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
    }
  };

  const formatNumber = (value) => {
    if (!value) return "";

    let cleanedValue = value.replace(/[^\d-,]/g, "");

    const parts = cleanedValue.split(",");
    const integerPart = parts[0];
    let decimalPart = parts[1];

    if (decimalPart && decimalPart.length > 2) {
      decimalPart = decimalPart.substring(0, 2);
    }

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return decimalPart
      ? `${formattedInteger},${decimalPart}`
      : formattedInteger;
  };

  const EtsyEarningsTable = ({ rows, setRows }) => {
    
    const addRow = () => {
      const maxId = rows.length > 0 ? Math.max(...rows.map(row => row.id)) : 0;
      const newRow = { id: maxId + 1, date: "", amount: "" };
      setRows([...rows, newRow]);
    };

    const deleteRow = (id) => {
      if (rows.length > 1) {
        setRows(rows.filter((row) => row.id !== id));
      }
    };

    const handleBlur = (id, field, value) => {
      console.log(value);
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        )
      );
    };

    const formatAmount = (value) => {
      const cleaned = value.replace(/[^\d,.]/g, "");

      if (cleaned.startsWith(",")) {
        return "0" + cleaned;
      }

      const parts = cleaned.split(",");
      if (parts.length > 2) {
        return parts[0] + "," + parts[1];
      }

      if (parts[1] && parts[1].length > 2) {
        return parts[0] + "," + parts[1].slice(0, 2);
      }

      return cleaned;
    };

    return (
      <StyledCard>
        <CardContent>
          <Typography variant="h5" component="h2" align="center" gutterBottom>
            ETSY Yatan Para
          </Typography>

          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tarih</TableCell>
                  <TableCell>ETSY Kazanç ($)</TableCell>
                  <TableCell align="center" style={{ width: 70 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={row.date} // defaultValue yerine value kullanıyoruz
                          onChange={(newValue) =>
                            handleBlur(row.id, "date", newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="standard"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell>
                      <TextField
                        defaultValue={row.amount} // Varsayılan değer
                        placeholder="0,00"
                        fullWidth
                        variant="outlined"
                        onBlur={(e) =>
                          handleBlur(
                            row.id,
                            "amount",
                            formatAmount(e.target.value)
                          )
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => deleteRow(row.id)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>

          <Box display="flex" justifyContent="center">
            <Button
              startIcon={<AddCircleIcon />}
              onClick={addRow}
              variant="text"
              color="primary"
              sx={{ mt: 2 }}
            >
              Yeni Satır Ekle
            </Button>
          </Box>
        </CardContent>
      </StyledCard>
    );
  };

  const EtsyEarningsUpdateTable = ({ rowsUpdate, setRowsUpdate }) => {

    const generateNewId = () => {
      const existingIds = rowsUpdate.map(row => row.id);
      return existingIds.length > 0 ? Math.max(...existingIds) + 1 : 1;
    };

    const addRow = () => {
    const newRow = { 
      id: generateNewId(), // Doğru ID üretme
      date: "", 
      amount: "" 
    };
    setRowsUpdate([...rowsUpdate, newRow]);
  };

    const deleteRow = (id) => {
      if (rowsUpdate.length > 1) {
        setRowsUpdate(rowsUpdate.filter((row) => row.id !== id));
      }
    };

    const handleBlur = (id, field, value) => {
      console.log(value);
      setRowsUpdate((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        )
      );
    };

    const formatAmount = (value) => {
      const cleaned = value.replace(/[^\d,.]/g, "");

      if (cleaned.startsWith(",")) {
        return "0" + cleaned;
      }

      const parts = cleaned.split(",");
      if (parts.length > 2) {
        return parts[0] + "," + parts[1];
      }

      if (parts[1] && parts[1].length > 2) {
        return parts[0] + "," + parts[1].slice(0, 2);
      }

      return cleaned;
    };
    console.log(rowsUpdate);
    return (
      <StyledCard>
        <CardContent>
          <Typography variant="h5" component="h2" align="center" gutterBottom>
            ETSY Yatan Para
          </Typography>

          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tarih</TableCell>
                  <TableCell>ETSY Kazanç ($)</TableCell>
                  <TableCell align="center" style={{ width: 70 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsUpdate || []).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={row.date ? dayjs(row.date) : null} // Gelen tarih bir dayjs nesnesine dönüştürülüyor
                          onChange={(newValue) => {
                            const formattedValue = newValue
                              ? dayjs(newValue).toISOString()
                              : ""; // Tarihi dayjs ile formatlıyoruz
                            handleBlur(row.id, "date", formattedValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="standard"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell>
                      <TextField
                        defaultValue={row.amount} // Varsayılan değer
                        placeholder="0,00"
                        fullWidth
                        variant="outlined"
                        onBlur={(e) =>
                          handleBlur(
                            row.id,
                            "amount",
                            formatAmount(e.target.value)
                          )
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => deleteRow(row.id)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>

          <Box display="flex" justifyContent="center">
            <Button
              startIcon={<AddCircleIcon />}
              onClick={addRow}
              variant="text"
              color="primary"
              sx={{ mt: 2 }}
            >
              Yeni Satır Ekle
            </Button>
          </Box>
        </CardContent>
      </StyledCard>
    );
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogDetails, setDialogDetails] = useState([]);

  const handleDialogOpen = (details) => {
    setDialogDetails(details);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogDetails([]);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#405D72",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "90vw",
            height: "98vh",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgb(159, 176, 176)",
            borderRadius: "25px",
            overflow: "hidden",
          }}
        >
         <IconButton
    onClick={() => navigate("/")}
    sx={{
      position: "absolute", // Mutlak konumlandırma
      top: 20, // Üstten 10px boşluk
      left: 150, // Soldan 10px boşluk
      display: "flex",
      alignItems: "center",
      gap: 1,
      padding: "5px 15px",
      borderRadius: "25px",
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#ccc",
      },
    }}
  >
    <Avatar
      src="/Images/Shops/Nile/DesignCluster1/Design4/Design4.svg"
      alt="Ana Menü"
      sx={{ width: 50, height: 50 }}
    />
    <Typography
      variant="h6"
      sx={{
        fontWeight: "bold",
        color: "#000",
      }}
    >
      Ana Menü
    </Typography>
  </IconButton>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2, // padding
                bgcolor: "rgb(200, 210, 210)",
                width: "60%",
                overflowX: "auto",
              }}
            >
              <Typography variant="h5" textAlign="center">
                Girilmiş Eforlar
              </Typography>
              <Box sx={{ marginTop: 3 }}>
                <DatePicker
                  label="Tarih Seçin"
                  name="startDate"
                  id="user-effort-date"
                  value={filterDate}
                  inputFormat="MM/DD/YYYY" // Tarih formatı
                  onChange={(newValue) => setFilterDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
              <Box sx={{ width: "130%", overflowX: "auto" }}>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxWidth: "70%",
                    maxHeight: "80vh",
                    margin: "auto",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                    marginTop: 2,
                    overflowX: "auto",
                    width: "110%",
                  }}
                >
                  <Table
                    sx={{
                      width: "110%",
                      maxWidth: "110%",
                      "& th": {
                        backgroundColor: "#405D72",
                        color: "white",
                        fontWeight: "bold",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      },
                      "& td": {
                        padding: "12px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                      "& tr:nth-of-type(even)": {
                        backgroundColor: "#f5f5f5",
                      },
                      "& tr:hover": {
                        backgroundColor: "#e0f7fa",
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Güncelle</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Mağaza</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Başlangıç Tarihi</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Bitiş Tarihi</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Satış Özeti</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Toplam Satış Miktarı</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Toplam Satış</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Toplam İade Miktarı</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Toplam İade</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Listeleme Ücretleri</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Marketing Ücretleri</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Kargo Ücretleri</strong>
                        </TableCell>
                        <TableCell>
                          <strong>ETSY Yatan Para</strong>
                        </TableCell>
                        <TableCell>
                          <strong>ETSY Yatan Para Toplamı</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Shipping Ücereti (Custom Hub)</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Ürün Toplam Ücereti (Custom Hub)</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Ürün Custom Maliyeti</strong>
                        </TableCell>
                        <TableCell>
                          <strong>İade ve Değişim</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Adet Girişi</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((row) => (
                        <TableRow key={row.ID}>
                          <TableCell>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setSelectedRow(row);
                                setSelectedUpdateValue(row.SelectedShop || "");
                                setFilterUpdateDate(dayjs(row.StartDate));
                                setEndFilterUpdateDate(dayjs(row.EndDate));
                                setUpdateFormValues({
                                  selectedShop: row.SelectedShop || "",
                                  startDate: dayjs(row.StartDate),
                                  endDate: dayjs(row.EndDate),
                                  salesSummary: formatNumericValue(
                                    row.salesSummary
                                  ),
                                  totalSalesAmount: row.totalSalesAmount,
                                  totalSales: formatNumericValue(
                                    row.totalSales
                                  ),
                                  totalRefundsAmounts: row.totalRefundsAmounts,
                                  totalRefunds: formatNumericValue(
                                    row.totalRefunds
                                  ),
                                  listingFees: formatNumericValue(
                                    row.listingFees
                                  ),
                                  marketingFees: formatNumericValue(
                                    row.marketingFees
                                  ),
                                  ShippingFees: formatNumericValue(
                                    row.ShippingFees
                                  ),
                                  CSVSalesSummary: row.CSVSalesSummary,
                                  filterStartDateCSV: dayjs(
                                    row.filterStartDateCSV
                                  ),
                                  filterEndDateCSV: dayjs(row.filterEndDateCSV),
                                  productTotalSum: formatNumericValue(
                                    row.productTotalSum
                                  ),
                                  productCustomCost: formatNumericValue(
                                    row.productCustomCost
                                  ),
                                  returnAndExchange: formatNumericValue(
                                    row.returnAndExchange
                                  ),
                                  ShippingFeesCustomHub: formatNumericValue(
                                    row.ShippingFeesCSV
                                  ),
                                  piaceInput: row.piaceInput,
                                  indexID: row.ID,
                                });

                                const updatedRows = row.details.map(
                                  (detail, index) => ({
                                    id: index + 1,
                                    date: detail.PaymentDate,
                                    amount: formatNumericValue(
                                      detail.PaymentFee
                                    ),
                                  })
                                );

                                setRowsUpdate(updatedRows);
                                setValue(1);
                              }}
                            >
                              <EditNoteIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>{row.SelectedShop}</TableCell>
                          <TableCell>
                            {new Date(row.StartDate).toLocaleDateString(
                              "tr-TR"
                            )}
                          </TableCell>
                          <TableCell>
                            {new Date(row.EndDate).toLocaleDateString("tr-TR")}
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                color: row.salesSummary < 0 ? "red" : "green",
                              }}
                            >
                              {row.salesSummary < 0 ? "-$" : "$"}{" "}
                              {formatNumericValue(Math.abs(row.salesSummary))}
                            </span>
                          </TableCell>
                          <TableCell>{row.totalSalesAmount}</TableCell>
                          <TableCell>
                            <span
                              style={{
                                color: row.totalSales < 0 ? "red" : "green",
                              }}
                            >
                              {row.totalSales < 0 ? "-$" : "$"}{" "}
                              {formatNumericValue(Math.abs(row.totalSales))}
                            </span>
                          </TableCell>
                          <TableCell>{row.totalRefundsAmounts}</TableCell>
                          <TableCell>
                            <span
                              style={{
                                color: row.totalRefunds < 0 ? "red" : "green",
                              }}
                            >
                              {row.totalRefunds < 0 ? "-$" : "$"}{" "}
                              {formatNumericValue(Math.abs(row.totalRefunds))}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                color: row.listingFees < 0 ? "red" : "red",
                              }}
                            >
                              {row.listingFees < 0 ? "-$" : "$"}{" "}
                              {formatNumericValue(Math.abs(row.listingFees))}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                color: row.marketingFees < 0 ? "red" : "red",
                              }}
                            >
                              {row.marketingFees < 0 ? "-$" : "$"}{" "}
                              {formatNumericValue(Math.abs(row.marketingFees))}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                color: row.ShippingFees < 0 ? "red" : "red",
                              }}
                            >
                              {row.ShippingFees < 0 ? "-$" : "$"}{" "}
                              {formatNumericValue(Math.abs(row.ShippingFees))}
                            </span>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="primary"
                              onClick={() => handleDialogOpen(row.details)}
                            >
                              <TableChartIcon />
                            </IconButton>

                            <Dialog
                              open={dialogOpen}
                              onClose={handleDialogClose}
                            >
                              <DialogTitle>Etsy Kazanç Kalemleri</DialogTitle>
                              <DialogContent>
                                <Table>
                                  <TableBody>
                                    {dialogDetails.map((detail) => (
                                      <TableRow key={detail.EtsyID}>
                                        <TableCell>
                                          {new Date(
                                            detail.PaymentDate
                                          ).toLocaleDateString("tr-TR")}
                                        </TableCell>
                                        <TableCell>
                                          $
                                          {formatNumericValue(
                                            detail.PaymentFee
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </DialogContent>
                            </Dialog>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                color: "green",
                              }}
                            >
                              {row.details && row.details.length > 0
                                ? `$${formatNumericValue(
                                    row.details.reduce(
                                      (acc, detail) =>
                                        acc +
                                        parseFloat(detail.PaymentFee || 0),
                                      0
                                    )
                                  )}`
                                : "No data"}
                            </span>
                            
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                color:
                                  row.ShippingFeesCSV < 0 ? "red" : "red",
                              }}
                            >
                              {row.ShippingFeesCSV < 0 ? "-$" : "$"}{" "}
                              {formatNumericValue(
                                Math.abs(row.ShippingFeesCSV)
                              )}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                color:
                                  row.productTotalSum < 0 ? "red" : "red",
                              }}
                            >
                              {row.productTotalSum < 0 ? "-$" : "$"}{" "}
                              {formatNumericValue(
                                Math.abs(row.productTotalSum)
                              )}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                color:
                                  row.productCustomCost < 0 ? "red" : "green",
                              }}
                            >
                              {row.productCustomCost < 0 ? "-$" : "$"}{" "}
                              {formatNumericValue(
                                Math.abs(row.productCustomCost)
                              )}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                color:
                                  row.returnAndExchange < 0 ? "red" : "red",
                              }}
                            >
                              {row.returnAndExchange < 0 ? "-$" : "$"}{" "}
                              {formatNumericValue(row.returnAndExchange)}
                            </span>
                          </TableCell>
                          <TableCell>{Math.abs(row.piaceInput)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </LocalizationProvider>

          {/* Sağ Panel */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // Artık sabit yükseklik kullanmıyoruz.
              // height: "100vh",
              minHeight: "100vh", // sayfa boyu kadar arkaplanın uzaması için
              bgcolor: "rgb(180, 190, 190)",
              p: 2,
            }}
          >
            <Box
              sx={{
                overflowY: "auto",

                mx: "auto",

                maxWidth: 1200,
                width: "100%",
                p: 2,
                boxSizing: "border-box",
                "&::-webkit-scrollbar": {
                  // Customize scrollbar
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // Dikey ortalama iptal edildi
                  alignItems: "center", // Yatay ortalama iptal edildi
                }}
              >
                <Box sx={{ borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChanges}
                    aria-label="basic tabs example"
                    sx={{
                      minHeight: 30,
                    }}
                  >
                    <Tab
                      label="Shop Veri Girişi"
                      {...a11yProps(0)}
                      sx={{
                        minWidth: 80,
                        padding: "6px 12px",
                        fontSize: "0.800rem",
                        lineHeight: "1",
                      }}
                    />
                    <Tab
                      label="Shop Veri Güncelleme"
                      {...a11yProps(1)}
                      sx={{
                        minWidth: 80,
                        padding: "6px 12px",
                        fontSize: "0.800rem",
                        lineHeight: "1",
                      }}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <form onSubmit={handleSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        component={Paper}
                        elevation={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          padding: 6,
                          maxWidth: 600,
                          margin: "auto",
                          bgcolor: "rgb(200, 210, 210)",
                        }}
                      >
                        <Typography variant="h5" textAlign="center">
                          Shop Veri Girişi
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <FormControl
                            sx={{ width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel id="dynamic-dropdown-label">
                              Shop
                            </InputLabel>
                            <Select
                              name="SelelectedShop"
                              labelId="dynamic-dropdown-label"
                              value={selectedValue}
                              onChange={handleChange}
                              label="Shop"
                            >
                              {options.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <DatePicker
                            label="Başlangıç Tarihi Seçin"
                            name="startDate"
                            id="user-effort-date"
                            value={filterDate}
                            inputFormat="MM/DD/YYYY" // Tarih formatı
                            onChange={(newValue) => {
                              setFilterDate(newValue);
                              // Eğer alt tarih manuel olarak değiştirilmediyse, alt tarihi güncelle
                              if (!formData.isCustomStartDate) {
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  filterStartDateCSV: newValue,
                                }));
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                          <DatePicker
                            label="Bitiş Tarih Seçin"
                            name="endDate"
                            id="user-effort-date"
                            value={filterEndDate}
                            inputFormat="MM/DD/YYYY" // Tarih formatı
                            onChange={(newValue) => {
                              setEndFilterDate(newValue);
                              // Eğer alt tarih manuel olarak değiştirilmediyse, alt tarihi güncelle
                              if (!formData.isCustomEndDate) {
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  filterEndDateCSV: newValue,
                                }));
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap={1}
                          sx={{ padding: "16px", width: "100%" }}
                        >
                          <Card sx={{ flex: 1, boxShadow: 3 }}>
                            <CardContent>
                              <Typography
                                variant="h5"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                ETSY Verileri
                              </Typography>
                              <Divider sx={{ my: 2 }} />
                              {/* Sales Section */}
                              <Typography
                                variant="h6"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                📝 Sales
                              </Typography>
                              <TextField
                                fullWidth
                                label="Sales Summary"
                                margin="normal"
                                name="salesSummary"
                                defaultValue={formData.salesSummary}
                                onBlur={handleBlur}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                  marginTop: -1.5,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Total Sales Amount"
                                  margin="normal"
                                  name="totalSalesAmount"
                                  defaultValue={formData.totalSalesAmount}
                                  onBlur={handleBlur}
                                />
                                <TextField
                                  fullWidth
                                  label="Total Sales"
                                  name="totalSales"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                  margin="normal"
                                  defaultValue={formData.totalSales}
                                  onBlur={handleBlur}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                  marginTop: -1.5,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Total Refunds Amounts"
                                  name="totalRefundsAmounts"
                                  margin="normal"
                                  defaultValue={formData.totalRefundsAmounts}
                                  onBlur={handleBlur}
                                />
                                <TextField
                                  fullWidth
                                  label="Total Refunds"
                                  name="totalRefunds"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                  margin="normal"
                                  defaultValue={formData.totalRefunds}
                                  onBlur={handleBlur}
                                />
                              </Box>
                              <Divider sx={{ my: 1 }} />

                              {/* Fees Section */}
                              <Typography
                                variant="h6"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                💳 Fees
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Listing Fees"
                                  name="listingFees"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                  margin="normal"
                                  defaultValue={formData.listingFees}
                                  onBlur={handleBlur}
                                />
                              </Box>
                              <Divider sx={{ my: 1 }} />

                              {/* Marketing Section */}
                              <Typography
                                variant="h6"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                📣 Marketing
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Marketing Fees"
                                  name="marketingFees"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                  margin="normal"
                                  defaultValue={formData.marketingFees}
                                  onBlur={handleBlur}
                                />
                              </Box>
                              <Divider sx={{ my: 1 }} />

                              {/* Shipping Section */}
                              <Typography
                                variant="h6"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                🚚 Shipping
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Shipping Fees"
                                  name="ShippingFees"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                  margin="normal"
                                  defaultValue={formData.ShippingFees}
                                  onBlur={handleBlur}
                                />
                              </Box>
                              <Box>
                                <EtsyEarningsTable
                                  rows={rows}
                                  setRows={setRows}
                                />
                              </Box>
                            </CardContent>
                          </Card>

                          <Card sx={{ flex: 1, boxShadow: 3 }}>
                            <CardContent>
                              <Typography
                                variant="h5"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                CSV Verileri
                              </Typography>
                              <Divider sx={{ my: 2 }} />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                  marginTop: 0,
                                }}
                              >
                                <DatePicker
                                  label="CSV Verisi Başlangıç Tarihi"
                                  name="startDateCSV"
                                  id="user-effort-date"
                                  value={formData.filterStartDateCSV}
                                  inputFormat="MM/DD/YYYY" // Tarih formatı
                                  onChange={(newValue) => {
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      filterStartDateCSV: newValue,
                                      isCustomStartDate: true,
                                    }));
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} fullWidth />
                                  )}
                                />
                                <DatePicker
                                  label="CSV Verisi Bitiş Tarihi"
                                  name="endDateCSV"
                                  id="user-effort-date"
                                  value={formData.filterEndDateCSV}
                                  inputFormat="MM/DD/YYYY"
                                  onChange={(newValue) => {
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      filterEndDateCSV: newValue,
                                      isCustomEndDate: true,
                                    }));
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} fullWidth />
                                  )}
                                />
                              </Box>
                              <TextField
                                fullWidth
                                label="Toplam Satış Adedi"
                                margin="normal"
                                name="CSVSalesSummary"
                                defaultValue={formData.CSVSalesSummary}
                                onBlur={handleBlur}
                              />
                            </CardContent>
                          </Card>

                          <Card sx={{ flex: 1, boxShadow: 3 }}>
                            <CardContent>
                              <Typography
                                variant="h5"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                Custom Hub Verileri
                              </Typography>
                              <Divider sx={{ my: 1 }} />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Shipping Ücreti"
                                  margin="normal"
                                  name="ShippingFeesCustomHub"
                                  defaultValue={formData.ShippingFeesCustomHub}
                                  onBlur={handleBlur}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <TextField
                                  fullWidth
                                  label="Ürün Toplam Ücereti (Baskı + Ürün)"
                                  margin="normal"
                                  name="productTotalSum"
                                  defaultValue={formData.productTotalSum}
                                  onBlur={handleBlur}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                  marginTop: -1.5,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Ürünün Custom Maliyeti"
                                  margin="normal"
                                  name="productCustomCost"
                                  defaultValue={formData.productCustomCost}
                                  onBlur={handleBlur}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <TextField
                                  fullWidth
                                  label="İade Ve Değişim"
                                  margin="normal"
                                  name="returnAndExchange"
                                  defaultValue={formData.returnAndExchange}
                                  onBlur={handleBlur}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                              <TextField
                                fullWidth
                                label="Adet Girişi"
                                margin="normal"
                                name="piaceInput"
                                defaultValue={formData.piaceInput}
                                onBlur={handleBlur}
                                style={{ marginTop: 0 }}
                              />
                            </CardContent>
                          </Card>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                          >
                            Shop Verisini Kaydet
                          </Button>
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </form>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <form onSubmit={handleUpdateSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        component={Paper}
                        elevation={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          padding: 6,
                          maxWidth: 600,
                          margin: "auto",
                          bgcolor: "rgb(200, 210, 210)",
                        }}
                      >
                        <Typography variant="h5" textAlign="center">
                          Shop Veri Güncelleme
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <FormControl
                            sx={{ width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel id="dynamic-dropdown-label">
                              Shop
                            </InputLabel>
                            <Select
                              name="SelelectedShop"
                              labelId="dynamic-dropdown-label"
                              value={selectedUpdateValue}
                              onChange={handleUpdateChange}
                              label="Shop"
                            >
                              {options.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <DatePicker
                            label="Başlangıç Tarihi Seçin"
                            name="startDate"
                            id="user-effort-date"
                            value={filterUpdateDate}
                            inputFormat="MM/DD/YYYY" // Tarih formatı
                            onChange={(newValue) => {
                              setFilterUpdateDate(newValue);
                              // Eğer alt tarih manuel olarak değiştirilmediyse, alt tarihi güncelle
                              if (!formUpdateValues.isCustomStartDate) {
                                setUpdateFormValues((prevFormData) => ({
                                  ...prevFormData,
                                  filterStartDateCSV: newValue,
                                }));
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                          <DatePicker
                            label="Bitiş Tarih Seçin"
                            name="endDate"
                            id="user-effort-date"
                            value={filterEndUpdateDate}
                            inputFormat="MM/DD/YYYY" // Tarih formatı
                            onChange={(newValue) => {
                              setEndFilterUpdateDate(newValue);
                              // Eğer alt tarih manuel olarak değiştirilmediyse, alt tarihi güncelle
                              if (!formUpdateValues.isCustomEndDate) {
                                setUpdateFormValues((prevFormData) => ({
                                  ...prevFormData,
                                  filterEndDateCSV: newValue,
                                }));
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap={1}
                          sx={{ padding: "16px" }}
                        >
                          <Card sx={{ flex: 1, boxShadow: 3 }}>
                            <CardContent>
                              <Typography
                                variant="h5"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                ETSY Verileri
                              </Typography>
                              <Divider sx={{ my: 2 }} />
                              {/* Sales Section */}
                              <Typography
                                variant="h6"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                📝 Sales
                              </Typography>
                              <TextField
                                fullWidth
                                label="Sales Summary"
                                margin="normal"
                                name="salesSummary"
                                defaultValue={formUpdateValues.salesSummary}
                                onBlur={handleBlurUpdate}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                  marginTop: -1.5,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Total Sales Amount"
                                  margin="normal"
                                  name="totalSalesAmount"
                                  defaultValue={
                                    formUpdateValues.totalSalesAmount
                                  }
                                  onBlur={handleBlurUpdate}
                                />
                                <TextField
                                  fullWidth
                                  label="Total Sales"
                                  name="totalSales"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                  margin="normal"
                                  defaultValue={formUpdateValues.totalSales}
                                  onBlur={handleBlurUpdate}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                  marginTop: -1.5,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Total Refunds Amounts"
                                  name="totalRefundsAmounts"
                                  margin="normal"
                                  defaultValue={
                                    formUpdateValues.totalRefundsAmounts
                                  }
                                  onBlur={handleBlurUpdate}
                                />
                                <TextField
                                  fullWidth
                                  label="Total Refunds"
                                  name="totalRefunds"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                  margin="normal"
                                  defaultValue={formUpdateValues.totalRefunds}
                                  onBlur={handleBlurUpdate}
                                />
                              </Box>
                              <Divider sx={{ my: 1 }} />

                              {/* Fees Section */}
                              <Typography
                                variant="h6"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                💳 Fees
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Listing Fees"
                                  name="listingFees"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                  margin="normal"
                                  defaultValue={formUpdateValues.listingFees}
                                  onBlur={handleBlurUpdate}
                                />
                              </Box>
                              <Divider sx={{ my: 1 }} />

                              {/* Marketing Section */}
                              <Typography
                                variant="h6"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                📣 Marketing
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Marketing Fees"
                                  name="marketingFees"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                  margin="normal"
                                  defaultValue={formUpdateValues.marketingFees}
                                  onBlur={handleBlurUpdate}
                                />
                              </Box>
                              <Divider sx={{ my: 1 }} />

                              {/* Shipping Section */}
                              <Typography
                                variant="h6"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                🚚 Shipping
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Shipping Fees"
                                  name="ShippingFees"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                  margin="normal"
                                  defaultValue={formUpdateValues.ShippingFees}
                                  onBlur={handleBlurUpdate}
                                />
                              </Box>
                              <Box>
                                <EtsyEarningsUpdateTable
                                  rowsUpdate={rowsUpdate}
                                  setRowsUpdate={setRowsUpdate}
                                />
                              </Box>
                            </CardContent>
                          </Card>

                          <Card sx={{ flex: 1, boxShadow: 3 }}>
                            <CardContent>
                              <Typography
                                variant="h5"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                CSV Verileri
                              </Typography>
                              <Divider sx={{ my: 2 }} />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                  marginTop: 0,
                                }}
                              >
                                <DatePicker
                                  label="CSV Verisi Başlangıç Tarihi"
                                  name="startDateCSV"
                                  id="user-effort-date"
                                  value={formUpdateValues.filterStartDateCSV}
                                  inputFormat="MM/DD/YYYY" // Tarih formatı
                                  onChange={(newValue) => {
                                    setUpdateFormValues((prevFormData) => ({
                                      ...prevFormData,
                                      filterStartDateCSV: newValue,
                                      isCustomStartDate: true,
                                    }));
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} fullWidth />
                                  )}
                                />
                                <DatePicker
                                  label="CSV Verisi Bitiş Tarihi"
                                  name="endDateCSV"
                                  id="user-effort-date"
                                  value={formUpdateValues.filterEndDateCSV}
                                  inputFormat="MM/DD/YYYY"
                                  onChange={(newValue) => {
                                    setUpdateFormValues((prevFormData) => ({
                                      ...prevFormData,
                                      filterEndDateCSV: newValue,
                                      isCustomEndDate: true,
                                    }));
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} fullWidth />
                                  )}
                                />
                              </Box>
                              <TextField
                                fullWidth
                                label="Toplam Satış Adedi"
                                margin="normal"
                                name="CSVSalesSummary"
                                defaultValue={formUpdateValues.CSVSalesSummary}
                                onBlur={handleBlurUpdate}
                              />
                            </CardContent>
                          </Card>

                          <Card sx={{ flex: 1, boxShadow: 3 }}>
                            <CardContent>
                              <Typography
                                variant="h5"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                Custom Hub Verileri
                              </Typography>
                              <Divider sx={{ my: 1 }} />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Shipping Ücreti"
                                  margin="normal"
                                  name="ShippingFeesCustomHub"
                                  defaultValue={
                                    formUpdateValues.ShippingFeesCustomHub
                                  }
                                  onBlur={handleBlurUpdate}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <TextField
                                  fullWidth
                                  label="Ürün Toplam Ücereti (Baskı + Ürün)"
                                  margin="normal"
                                  name="productTotalSum"
                                  defaultValue={
                                    formUpdateValues.productTotalSum
                                  }
                                  onBlur={handleBlurUpdate}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 2,
                                  marginTop: -1.5,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Ürünün Custom Maliyeti"
                                  margin="normal"
                                  name="productCustomCost"
                                  defaultValue={
                                    formUpdateValues.productCustomCost
                                  }
                                  onBlur={handleBlurUpdate}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $-
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <TextField
                                  fullWidth
                                  label="İade Ve Değişim"
                                  margin="normal"
                                  name="returnAndExchange"
                                  defaultValue={
                                    formUpdateValues.returnAndExchange
                                  }
                                  onBlur={handleBlurUpdate}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                              <TextField
                                fullWidth
                                label="Adet Girişi"
                                margin="normal"
                                name="piaceInput"
                                defaultValue={formUpdateValues.piaceInput}
                                onBlur={handleBlurUpdate}
                                style={{ marginTop: 0 }}
                              />
                            </CardContent>
                          </Card>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                            name="update"
                          >
                            Shop Verisini Güncelle
                          </Button>
                          {user?.ReportAdmin === 1 && (
                            <Button
                            variant="outlined"
                            color="secondary"
                            type="submit"
                            name="delete" 
                          >
                            Shop Verisini Sil
                          </Button>
                            )}
                          
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </form>
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ShopEffort;
