import React, { useEffect, useState, useMemo } from "react";
import "./CSS/MaintenanceScreen.css";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import Button from "@mui/material/Button";
import { pink, deepPurple } from "@mui/material/colors";
import { useUser } from "./Context/UserContext";
import { Alert, Snackbar } from "@mui/material";
import {
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Container,
  CardHeader,
  Checkbox,
  Chip,
  Link,
  TablePagination,
  InputAdornment,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { tr } from "date-fns/locale";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  ComposedChart,
  Area,
  AreaChart,
  Cell,
} from "recharts";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { TimePicker } from "@mui/x-date-pickers";
import { CoPresentSharp, Edit as EditIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Warning as WarningIcon,
  Close as CloseIcon,
  Store as StoreIcon,
} from "@mui/icons-material";
import { set } from "date-fns";
import InfoIcon from "@mui/icons-material/Info";
import { Search } from "lucide-react";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import "./CSS/UserReport.css";
import { Image as ImageIcon } from "lucide-react";
import { saveAs } from "file-saver";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Pagination } from '@mui/material';

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        },
      },
    ],
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const UserReports = () => {
  let status = "1";
  let path =
    status === "0" ? "http://localhost:3003" : "https://outlettee.com:3003";

  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [userInfos, setUserInfos] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const { user } = useUser();
  console.log(user);
  const COLORS = [
    "#FA8072", // Somon
    "#FF5733", // Turuncu-kırmızı
    "#FFBD33", // Altın sarısı
    "#75FF33", // Açık yeşil
    "#33FF57", // Yeşil ton
    "#33FFBD", // Turkuaz ton
    "#33DBFF", // Açık mavi
    "#3375FF", // Mavi
    "#5733FF", // Mor ton
    "#BD33FF", // Eflatun
    "#FF33DB", // Pembe ton
    "#FF3375", // Pembe-kırmızı
    "#FF3333", // Kırmızı
    "#FF6F61", // Mercan
    "#FFD700", // Altın
    "#ADFF2F", // Yeşil-sarı
    "#7FFF00", // Chartreuse
    "#00FA9A", // Orman yeşili
    "#00CED1", // Koyu Turkuaz
    "#1E90FF", // Dodger Mavisi
    "#4169E1", // Kraliyet Mavisi
    "#8A2BE2", // Mavi-mor
    "#9400D3", // Koyu mor
    "#C71585", // Orkide
    "#FF1493", // Pembe
    "#FF7F50", // Mercan
    "#CD5C5C", // Koyu mercan
    "#F08080", // Açık mercan
    "#DBFF33", // Sarı-yeşil
    "#FF00FF", // Fuşya
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenuClick = (menu) => {
    setSelectedMenu(menu); // Menü tıklamalarını yakala
  };

  const [selectedUser, setSelectedUser] = useState("all");
  const [selectedShop, setSelectedShop] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedShopData, setSelectedShopData] = useState("all");
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userEffortsFees, setUserEffortsFees] = useState([]);
  const [shopExpensesData, setShopExpensesData] = useState([]);
  useEffect(() => {
    const fetchShopData = async () => {
      const UserID = user?.ID;
      const isAdmin = user?.Admin;
      try {
        const response = await axios.post(
          path + "/api/GetUserEffortViaParamaters",
          {
            UserID: UserID,
            isAdmin: isAdmin,
          }
        );
        const parsedData = response.data;
        setUserData(parsedData); // userData state'i burada güncelleniyor
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    const fetchShopExpenses = async () => {
      try {
        setShopExpensesData([]);
        const response = await axios.get(path + "/api/GetAllShopExpenses");
        const parsedData = response.data;
        console.log(parsedData);
        setShopExpensesData(parsedData);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };
    fetchShopExpenses();

    fetchShopData();

    const fetchShopAuth = async () => {
      try {
        const response = await axios.post(path + "/api/getUserShops", {
          UserID: user?.ID,
        });
        const options = response.data.data.map((shop) => ({
          value: shop.ShopName,
          label: shop.ShopName,
        }));
        setOptions(options);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };
    fetchShopAuth();

    const fetchShop = async () => {
      try {
        setData([]);
        const response = await axios.get(path + "/api/GetAllShopData");
        const parsedData = response.data;
        console.log(parsedData);
        setData(parsedData);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };
    fetchShop();

    const fetchUserFee = async () => {
      try {
        const response = await axios.get(path + "/api/AllEffortsFees");
        const parsedData = response.data;

        if (parsedData && parsedData.length > 0) {
          const updatedEfforts = userData.map((user) => {
            const existingEffort = parsedData.find(
              (effort) => effort.userId === user.UserID
            );

            return {
              userId: user.UserID,
              userName: user.UserName,
              effortType1: existingEffort?.effortType1 || "",
              effortType1Fee: existingEffort?.effortType1Fee || "",
              effortType2: existingEffort?.effortType2 || "",
              effortType2Fee: existingEffort?.effortType2Fee || "",
            };
          });

          setUserEffortsFees(updatedEfforts);
        }
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    fetchUserFee();
  }, []);

  useEffect(() => {
    if (userData && userData.length > 0) {
      const updatedEfforts = userData.map((user) => ({
        userId: user.UserID,
        userName: user.UserName,
        effortType1: "",
        effortType1Fee: "",
        effortType2: "",
        effortType2Fee: "",
      }));
      setUserEffortsFees(updatedEfforts);
    }
  }, [userData]); // userData'ya bağımlı


  const [options, setOptions] = useState([]);

  // Benzersiz kullanıcı ve mağaza listelerini oluştur
  const users = [...new Set(userData.map((item) => item.UserName))];
  const shops = [...new Set(userData.map((item) => item.Shop))];

  const filteredData = useMemo(() => {
    return userData.filter((item) => {
      const userMatch =
        selectedUser === "all" || item.UserName === selectedUser;
      const shopMatch = selectedShop === "all" || item.Shop === selectedShop;

      // Tarih filtresi
      const itemDate = new Date(item.EffortDate);
      const dateMatch =
        (!startDate || itemDate >= startDate) &&
        (!endDate || itemDate <= endDate);

      return userMatch && shopMatch && dateMatch;
    });
  }, [selectedUser, selectedShop, userData, startDate, endDate]);

  const convertTimeSummaryToHours = (timeSummary) => {
    const [hours, minutes] = timeSummary.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const exactHours = totalMinutes / 60; // Yuvarlama YOK
    return exactHours;
  };

  const totalCalculations = useMemo(() => {
    const calculations = filteredData.reduce(
      (total, item) => {
        const hours = convertTimeSummaryToHours(item.TimeSummary);

        // Kullanıcının fee bilgilerini bul
        const userFees = userEffortsFees.find(
          (fee) => fee.userId === item.UserID
        );

        if (!userFees) return total;

        // Shop'a göre fee seçimi ve hesaplama
        if (item.Shop === "Arge") {
          if (user.Admin === 1 || user.Username === selectedUser) {
            const hourlyFee = parseFloat(userFees.effortType2Fee);
            return {
              ...total,
              argeHours: total.argeHours + hours,
              argeCost: total.argeCost + hours * hourlyFee,
              hours: total.hours + hours,
              cost: total.cost + hours * hourlyFee,
            };
          } else {
            return {
              ...total,
              argeHours: total.argeHours + hours,
              argeCost: total.argeCost + 0, // ARGE maliyeti sıfır
              hours: total.hours + hours,
              cost: total.cost + 0, // ARGE için maliyet eklenmez
            };
          }
        } else {
          const hourlyFee = parseFloat(userFees.effortType1Fee);
          return {
            ...total,
            otherHours: total.otherHours + hours,
            otherCost: total.otherCost + hours * hourlyFee,
            hours: total.hours + hours,
            cost: total.cost + hours * hourlyFee,
          };
        }
      },
      {
        hours: 0,
        cost: 0,
        argeHours: 0,
        argeCost: 0,
        otherHours: 0,
        otherCost: 0,
      }
    );

    return {
      totalWorkHours: calculations.hours.toFixed(2),
      totalCost: calculations.cost.toFixed(2),
      argeWorkHours: calculations.argeHours.toFixed(2),
      argeCost: calculations.argeCost.toFixed(2),
      otherWorkHours: calculations.otherHours.toFixed(2),
      otherCost: calculations.otherCost.toFixed(2),
    };
  }, [filteredData, userEffortsFees, user, selectedUser]);

  const shopCalculations = useMemo(() => {
    const calculations = {};

    filteredData.forEach((item) => {
      if (item.Shop === "") return;

      const hours = convertTimeSummaryToHours(item.TimeSummary);
      const userFees = userEffortsFees.find(
        (fee) => fee.userId === item.UserID
      );

      if (!userFees) return;

      const hourlyFee =
        item.Shop === "ARGE"
          ? parseFloat(userFees.effortType2Fee)
          : parseFloat(userFees.effortType1Fee);

      if (!calculations[item.Shop]) {
        calculations[item.Shop] = { hours: 0, cost: 0 };
      }

      calculations[item.Shop].hours += hours;
      calculations[item.Shop].cost += hours * hourlyFee;
    });

    return Object.entries(calculations).map(([shop, calc]) => ({
      shop,
      hours: Number(calc.hours.toFixed(2)),
      cost: Number(calc.cost.toFixed(2)),
    }));
  }, [filteredData, userEffortsFees]);

  const totalWorkHours = useMemo(() => {
    return filteredData
      .reduce((total, item) => {
        return total + convertTimeSummaryToHours(item.TimeSummary);
      }, 0)
      .toFixed(2);
  }, [filteredData]);

  const shopHours = useMemo(() => {
    const hours = {};
    console.log(filteredData);
    filteredData.forEach((item) => {
      if (item.Shop === "") {
        return;
      }
      const timeSpent = convertTimeSummaryToHours(item.TimeSummary);
      hours[item.Shop] = (hours[item.Shop] || 0) + timeSpent;
    });

    return Object.entries(hours).map(([shop, hours]) => ({
      shop,
      hours: Number(hours.toFixed(2)),
    }));
  }, [filteredData]);

  const dailyEfforts = useMemo(() => {
    const effortsByDate = {};
    filteredData.forEach((item) => {
      const date = new Date(item.EffortDate).toLocaleDateString();
      const timeSpent = convertTimeSummaryToHours(item.TimeSummary);
      effortsByDate[date] = (effortsByDate[date] || 0) + timeSpent;
    });
    return Object.entries(effortsByDate).map(([date, hours]) => ({
      date,
      hours: Number(hours.toFixed(2)),
    }));
  }, [filteredData]);

  const taskDistribution = useMemo(() => {
    const tasks = {};
    filteredData.forEach((item) => {
      const taskList = item.SelectedEfforts.split(",");
      const timePerTask =
        convertTimeSummaryToHours(item.TimeSummary) / taskList.length;
      taskList.forEach((task) => {
        tasks[task.trim()] = (tasks[task.trim()] || 0) + timePerTask;
      });
    });
    return Object.entries(tasks).map(([name, value]) => ({
      name,
      value: Number(value.toFixed(2)),
    }));
  }, [filteredData]);

  const UserDetailReports = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h4" component="h1">
              Efor Raporu
            </Typography>

            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Kullanıcı</InputLabel>
                <Select
                  value={selectedUser}
                  label="Kullanıcı"
                  onChange={(e) => setSelectedUser(e.target.value)}
                >
                  <MenuItem value="all">Tüm Kullanıcılar</MenuItem>
                  {users.map((user) => (
                    <MenuItem key={user} value={user}>
                      {user}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Mağaza</InputLabel>
                <Select
                  value={selectedShop}
                  label="Mağaza"
                  onChange={(e) => setSelectedShop(e.target.value)}
                >
                  <MenuItem value="all">Tüm Mağazalar</MenuItem>
                  {shops.map((shop) => (
                    <MenuItem key={shop} value={shop}>
                      {shop}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <DatePicker
                label="Başlangıç Tarihi"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
                format="dd/MM/yyyy"
              />

              <DatePicker
                label="Bitiş Tarihi"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
                format="dd/MM/yyyy"
                minDate={startDate}
              />
            </Box>
          </Box>

          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" color="primary">
                Toplam Çalışma Süresi: {totalWorkHours} saat
              </Typography>

              {(user.Admin === 1 || user.Username === selectedUser) && (
                <Typography variant="h6" color="secondary">
                  Toplam Maliyet: {totalCalculations.totalCost} USD
                  <Typography
                    component="span"
                    sx={{
                      color: "warning.main", // sarı renk
                      fontSize: "0.8em", // ana yazıdan daha küçük
                      marginLeft: 1, // sol boşluk
                    }}
                  >
                    (ARGE: {totalCalculations.argeCost} USD, Diğer:{" "}
                    {totalCalculations.otherCost} USD)
                  </Typography>
                </Typography>
              )}
            </CardContent>
          </Card>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Günlük Efor Dağılımı
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={dailyEfforts}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="hours"
                          stroke="#1976d2"
                          name="Saat"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Mağaza Bazlı Toplam Efor
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={shopHours}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="shop" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="hours"
                          fill="#2e7d32"
                          name="Toplam Saat"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Görev Dağılımı
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={taskDistribution}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          label
                        >
                          {/* Her dilim için farklı renk vermek */}
                          {taskDistribution.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Detaylı Efor Listesi
                  </Typography>
                  <EffortTable
                    filteredData={filteredData}
                    onUpdateEffort={async (updatedEffort) => {
                      // API çağrısı yapılacak
                      console.log(updatedEffort);
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    );
  };

  const EffortTable = ({ filteredData, onUpdateEffort }) => {
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [selectedEffort, setSelectedEffort] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [screenshotsDialogOpen, setScreenshotsDialogOpen] = useState(false);

    const handleScreenshotsClick = (effort) => {
      setSelectedEffort(effort);
      setScreenshotsDialogOpen(true);
    };

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const searchedData = filteredData
      .filter((item) =>
        Object.values(item).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
      .sort((a, b) => {
        // Tarihleri karşılaştırma (yeniden eskiye)
        const dateA = new Date(a.EffortDate);
        const dateB = new Date(b.EffortDate);

        // Aynı tarihler için saat karşılaştırması
        if (dateA.getTime() === dateB.getTime()) {
          const timeA = a.StartHour.split(":").map(Number);
          const timeB = b.StartHour.split(":").map(Number);

          // Saat karşılaştırması
          if (timeA[0] !== timeB[0]) {
            return timeB[0] - timeA[0];
          }
          // Dakika karşılaştırması
          return timeB[1] - timeA[1];
        }

        return dateB.getTime() - dateA.getTime();
      });
    // Filtrelenmiş ve sayfalama uygulanmış veriler
    const paginatedData = searchedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    // Arama terimini kullanarak verileri filtrele

    const handleEditClick = (effort) => {
      setSelectedEffort(effort);
      setEditDialogOpen(true);
    };

    const handleDetailsClick = (effort) => {
      console.log(effort);
      setSelectedEffort(effort);
      setDetailsDialogOpen(true);
    };

    const handleSave = async (updatedEffort) => {
      console.log(updatedEffort);
      try {
        const indexID = updatedEffort.Id;
        const selectedShop = updatedEffort.Shop;
        const UserID = user?.ID;
        const username = user?.Username;
        const startDate = dayjs(updatedEffort.EffortDate).format("DD/MM/YYYY");
        const startTime = updatedEffort.StartHour;
        const endTime = updatedEffort.EndHour;
        const description = updatedEffort.Explanation;
        const storeLinks = updatedEffort.ShopLinks;
        const sellerLinks = updatedEffort.PurchLinks;
        const SelectedEfforts = updatedEffort.SelectedEfforts.map(
          (item) => `${item}`
        ).join(", ");

        const start = dayjs(`1970-01-01T${updatedEffort.StartHour}`);
        let end = dayjs(`1970-01-01T${updatedEffort.EndHour}`);

        if (end.isBefore(start)) {
          end = end.add(1, "day");
        }

        const totalMinutes = end.diff(start, "minute");
        const hours = Math.floor(totalMinutes / 60); // 2
        const minutes = totalMinutes % 60; // 5

        // Dakika kısmını 2 basamakla padStart yapalım
        const formattedMinutes = String(minutes).padStart(2, "0"); // "05"
        const TimeSummary = `${hours}:${formattedMinutes}`;

        console.log(TimeSummary); // "2:05"
        // Zorunlu alanlar dolu mu diye kontrol
        if (!startDate || !startTime || !endTime) {
          setAlertSeverity("error");
          setAlertMessage("Lütfen tüm zorunlu alanları doldurunuz!");
          setAlertOpen(true);
          return;
        }

        // Kullanıcı bilgisi var mı?
        if (!UserID || !username) {
          setAlertSeverity("error");
          setAlertMessage(
            "Beklenmedik Bir Hata Oluştu. Lütfen Yöneticinizle İletişime Geçiniz!"
          );
          setAlertOpen(true);
          return;
        }

        // Sunucuya güncelleme isteği at
        const response = await axios.post(`${path}/api/UpdateFormData`, {
          indexID,
          UserID,
          username,
          selectedShop,
          startDate,
          startTime,
          endTime,
          SelectedEfforts,
          description,
          storeLinks,
          sellerLinks,
          TimeSummary,
        });

        // Başarılı ise alert göster
        setAlertSeverity("success");
        setAlertMessage("Efor başarıyla güncellendi.");
        setAlertOpen(true);
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage("Efor güncellenirken bir hata oluştu." + error);
        setAlertOpen(true);
      }
    };
    const DetailsDialog = ({ open, onClose, effort }) => {
      if (!effort) return null;

      // Helper function to check if base64 string is valid
      const isValidBase64 = (str) => {
        if (!str) return false;
        try {
          return str.startsWith("data:image") || btoa(atob(str)) === str;
        } catch (err) {
          return false;
        }
      };

      // Get valid images from effort
      const images = ["Image1", "Image2", "Image3"]
        .map((key) => effort[key])
        .filter(isValidBase64);

      const separateLinks = (linkString) => {
        if (!linkString) return [];

        // URL'leri düzgün ayırmak için düzenleme yap
        const urlPattern = /(https?:\/\/[^\s]+)/g;
        return (
          linkString.replace(/(https)(?=:\/\/)/g, " $1").match(urlPattern) || []
        );
      };

      const LinkTable = ({ title, links }) => (
        <div className="space-y-4">
          <h3 className="text-base font-medium">{title}</h3>
          {links.length > 0 ? (
            <table className="w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 px-4 py-2">Linkler</th>
                </tr>
              </thead>
              <tbody>
                {links.map((link, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="border border-gray-300 px-4 py-2">
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 hover:underline"
                      >
                        {link}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-gray-500">Link bulunmuyor</p>
          )}
        </div>
      );

      return (
        <Dialog open={open}>
          <DialogTitle className="text-lg font-semibold">
            Ayrıntılı Bilgiler
          </DialogTitle>
          <DialogContent>
            <div className="space-y-6 p-4">
              <div className="space-y-2">
                <h3 className="text-base font-medium">Adet Sayısı:</h3>
                <p>{effort.Piace}</p>
              </div>
              <div className="space-y-6">
                <LinkTable
                  title="Mağaza Linkleri"
                  links={separateLinks(effort.ShopLinks)}
                />
                <LinkTable
                  title="Satıcı Linkleri"
                  links={separateLinks(effort.PurchLinks)}
                />
              </div>

              {/* Resimler */}
              {images.length > 0 && (
                <div className="space-y-2">
                  <h3 className="text-base font-medium">Resimler:</h3>
                  <div className="grid grid-cols-1 gap-4 place-items-center">
                    {images.map((image, index) => (
                      <div key={index} className="relative">
                        <Box
                          component="img"
                          src={
                            image.startsWith("data:image")
                              ? image
                              : `data:image/jpeg;base64,${image}`
                          }
                          alt={`Resim ${index + 1}`}
                          sx={{
                            width: 400,
                            height: 400,
                            objectFit: "contain",
                            borderRadius: "8px",
                            backgroundColor: "gray",
                          }}
                          onError={(e) => {
                            e.target.src = "/api/placeholder/200/200";
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outline">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      );
    };
    const effortOptions = [
      "MESAJLARA CEVAP",
      "CUSTOMHUB",
      "CUSTOM ORDER HAZIRLANMASI",
      "URUN BULMA",
      "URUN YUKLEME",
      "MAGAZA GENEL KONTROL",
      "TOPLANTI",
      "PIYASA ARSTIRMASI AR-GE",
      "REKLAM OPTIMIZASYONU",
      "URETIM TAKIP VE KONTROL",
      "DIZAYN CIZIMI",
      "MOCKUP EKLEME",
      "TRADEMARK KONTROLU",
      "ARGE",
      "FASHION FOLLOW",
      "YONETIM VE KONTROL",
      "DIGER",
    ];

    const ScreenshotsDialog = ({ open, onClose, effort }) => {
      const [screenshots, setScreenshots] = useState([]);
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(null);
      const [page, setPage] = useState(0);
      const [itemsPerPage] = useState(60);
  
      // Chunk processing function to handle large binary data
      const processBase64Chunk = (buffer) => {
          if (!buffer || !buffer.data) return null;
          
          try {
              // Convert to Uint8Array in smaller chunks
              const chunk = new Uint8Array(buffer.data);
              let binary = '';
              const chunkSize = 1024;
              
              for (let i = 0; i < chunk.length; i += chunkSize) {
                  const subChunk = chunk.slice(i, i + chunkSize);
                  binary += String.fromCharCode.apply(null, subChunk);
              }
              
              return `data:image/jpeg;base64,${btoa(binary)}`;
          } catch (error) {
              console.error("Error processing image chunk:", error);
              return null;
          }
      };
  
      const fetchScreenshots = async () => {
          if (!effort) return;
  
          setLoading(true);
          setError(null);
          setPage(0); // Reset page when fetching new data
  
          try {
              const effortDateLocal = dayjs(effort.EffortDate).format("YYYY-MM-DD");
              const startDateTime = dayjs(
                  `${effortDateLocal} ${effort.StartHour}`,
                  "YYYY-MM-DD HH:mm"
              ).format("YYYY-MM-DD HH:mm:ss");
              const endDateTime = dayjs(
                  `${effortDateLocal} ${effort.EndHour}`,
                  "YYYY-MM-DD HH:mm"
              ).format("YYYY-MM-DD HH:mm:ss");
  
              console.log("Start DateTime:", startDateTime);
              console.log("End DateTime:", endDateTime);
  
              const response = await axios.post(`${path}/api/GetScreenShots`, {
                  startDate: startDateTime,
                  endDate: endDateTime,
                  userId: effort.UserID,
              });
  
              // Process screenshots in chunks
              const processedScreenshots = response.data.map(screenshot => ({
                  ...screenshot,
                  Base64Image: processBase64Chunk(screenshot.Base64Image)
              }));
  
              setScreenshots(processedScreenshots);
          } catch (err) {
              console.error("Screenshot fetch error:", err);
              setError("Ekran görüntüleri alınırken bir hata oluştu.");
          } finally {
              setLoading(false);
          }
      };
  
      React.useEffect(() => {
          if (open) {
              fetchScreenshots();
          }
      }, [open, effort]);
  
      // Calculate pagination
      const pageCount = Math.ceil(screenshots.length / itemsPerPage);
      const currentPageItems = screenshots.slice(
          page * itemsPerPage,
          (page + 1) * itemsPerPage
      );
  
      return (
          <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
              <DialogTitle>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography>Ekran Görüntüleri</Typography>
                      {screenshots.length > 0 && (
                          <Typography variant="body2" color="text.secondary">
                              Toplam: {screenshots.length} görüntü
                          </Typography>
                      )}
                  </Box>
              </DialogTitle>
              <DialogContent>
                  {loading ? (
                      <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height={400}
                      >
                          <Typography sx={{ ml: 2 }}>Yükleniyor...</Typography>
                      </Box>
                  ) : error ? (
                      <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          color="error.main"
                      >
                          {error}
                      </Box>
                  ) : screenshots.length === 0 ? (
                      <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          color="text.secondary"
                      >
                          Bu zaman aralığında ekran görüntüsü bulunmamaktadır.
                      </Box>
                  ) : (
                      <>
                          <Box
                              sx={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr",
                                  gap: 3,
                                  justifyItems: "center",
                                  mt: 2,
                                  mb: 2
                              }}
                          >
                              {currentPageItems.map((screenshot, index) => (
                                  <Box
                                      key={index}
                                      sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          width: "100%",
                                          maxWidth: 1000,
                                          border: "1px solid",
                                          borderColor: "divider",
                                          borderRadius: 2,
                                          p: 2,
                                          boxShadow: 1,
                                      }}
                                  >
                                      <Box
                                          sx={{
                                              width: "100%",
                                              maxHeight: 700,
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              overflow: "hidden",
                                          }}
                                      >
                                          {screenshot.Base64Image ? (
                                              <Box
                                                  component="img"
                                                  src={screenshot.Base64Image}
                                                  alt={`Ekran Görüntüsü ${index + 1}`}
                                                  sx={{
                                                      maxWidth: "100%",
                                                      height: "auto",
                                                      objectFit: "contain",
                                                  }}
                                                  onError={(e) => {
                                                      e.target.src = "/api/placeholder/400/300";
                                                  }}
                                              />
                                          ) : (
                                              <Typography color="text.secondary">
                                                  Görüntü yüklenemedi
                                              </Typography>
                                          )}
                                      </Box>
                                      <Typography variant="caption" color="text.secondary" mt={1}>
                                          {dayjs(screenshot.UploadDate).format("DD.MM.YYYY HH:mm:ss")}
                                      </Typography>
                                  </Box>
                              ))}
                          </Box>
                          {screenshots.length > itemsPerPage && (
                              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
                                  <Pagination
                                      count={pageCount}
                                      page={page + 1}
                                      onChange={(e, newPage) => setPage(newPage - 1)}
                                      color="primary"
                                      siblingCount={2}
                                      boundaryCount={2}
                                      showFirstButton
                                      showLastButton
                                  />
                              </Box>
                          )}
                      </>
                  )}
              </DialogContent>
              <DialogActions>
                  <Button onClick={onClose} variant="outlined">
                      Kapat
                  </Button>
              </DialogActions>
          </Dialog>
      );
  };

    return (
      <div className="space-y-4">
        {/* Arama Alanı */}

        <ScreenshotsDialog
          open={screenshotsDialogOpen}
          onClose={() => setScreenshotsDialogOpen(false)}
          effort={selectedEffort}
        />

        <EditEffortDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          effort={selectedEffort || {}}
          onSave={handleSave}
          shops={shops || []}
          allEffortTypes={effortOptions || []}
        />

        {/* Details Dialog */}
        <DetailsDialog
          open={detailsDialogOpen}
          onClose={() => setDetailsDialogOpen(false)}
          effort={selectedEffort}
        />

        <div className="flex items-center space-x-2 p-4 bg-white rounded-lg shadow">
          <Search className="text-gray-400" />
          <input
            type="text"
            placeholder="Ara..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Tablo */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>İşlemler</TableCell>
                <TableCell>Tarih</TableCell>
                <TableCell>Kullanıcı</TableCell>
                <TableCell>Mağaza</TableCell>
                <TableCell>Başlangıç</TableCell>
                <TableCell>Bitiş</TableCell>
                <TableCell>Toplam Süre</TableCell>
                <TableCell>Görevler</TableCell>
                <TableCell>Ayrıntılar</TableCell>
                {user.Admin === 1 && <TableCell>Ekran Görüntüleri</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((item) => (
                <TableRow key={item.Id}>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleEditClick(item)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {new Date(item.EffortDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{item.UserName}</TableCell>
                  <TableCell>{item.Shop}</TableCell>
                  <TableCell>{item.StartHour}</TableCell>
                  <TableCell>{item.EndHour}</TableCell>
                  <TableCell>{item.TimeSummary}</TableCell>
                  <TableCell>
                    {item.SelectedEfforts === "DIGER"
                      ? `${item.SelectedEfforts} -> ${item.Explanation}`
                      : item.SelectedEfforts}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleDetailsClick(item)}
                      color="info"
                    >
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                  {user.Admin === 1 && (
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleScreenshotsClick(item)}
                        color="secondary"
                      >
                        <ImageIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* Sayfalama */}
          <TablePagination
            component="div"
            count={searchedData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 50]}
            labelRowsPerPage="Sayfa başına satır:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} arası, toplam: ${count}`
            }
          />
        </TableContainer>
      </div>
    );
  };

  const EditEffortDialog = ({
    effort,
    open,
    onClose,
    onSave,
    shops,
    allEffortTypes,
  }) => {
    const [formData, setFormData] = useState({
      StartHour: effort.StartHour || "",
      EndHour: effort.EndHour || "",
      Shop: effort.Shop || "",
      SelectedEfforts: Array.isArray(effort.SelectedEfforts)
        ? effort.SelectedEfforts
        : effort.SelectedEfforts?.split(",").map((item) => item.trim()) || [],
      Explanation: effort.Explanation || "",
      ShopLinks: effort.ShopLinks || "",
      PurchLinks: effort.PurchLinks || "",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "SelectedEfforts") {
        setFormData((prev) => ({
          ...prev,
          SelectedEfforts: typeof value === "string" ? value.split(",") : value,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    };

    const handleSubmit = () => {
      onSave({ ...effort, ...formData });
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Efor Kaydını Güncelle</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              mt: 2,
            }}
          >
            <FormControl fullWidth sx={{}}>
              <TextField
                name="StartHour"
                label="Başlangıç Saati"
                type="time"
                value={formData.StartHour}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
            </FormControl>

            <FormControl fullWidth sx={{}}>
              <TextField
                name="EndHour"
                label="Bitiş Saati"
                type="time"
                value={formData.EndHour}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Mağaza</InputLabel>
            <Select
              name="Shop"
              value={formData.Shop}
              onChange={handleChange}
              label="Mağaza"
            >
              {shops.map((shop, index) => (
                <MenuItem key={index} value={shop}>
                  {shop}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Yapılan İşler</InputLabel>
            <Select
              name="SelectedEfforts"
              multiple
              value={formData.SelectedEfforts}
              onChange={handleChange}
              label="Yapılan İşler"
            >
              {allEffortTypes.map((effort, index) => (
                <MenuItem key={index} value={effort}>
                  {effort}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="Explanation"
              label="Açıklama"
              multiline
              rows={4}
              value={formData.Explanation}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="storeLinks"
              label="Mağaza Linkleri"
              multiline
              rows={2}
              value={formData.ShopLinks}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="sellerLinks"
              label="Satıcı Linkleri"
              multiline
              rows={2}
              value={formData.PurchLinks}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary">
            İptal
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Güncelle
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const calculateMetrics = (shop, startDate, endDate, userEarnings) => {
    const expenses = calculateExpenses(
      shop.SelectedShop,
      shop.StartDate,
      shop.EndDate
    ).toFixed(2);

    const shopExpenses =
      Math.abs(shop.ShippingFeesCSV) +
      Math.abs(shop.productCustomCost) +
      Math.abs(shop.returnAndExchange) +
      Math.abs(shop.productTotalSum);

    const totalProfit = (
      Math.abs(shop.PaymentFee) -
      shopExpenses -
      expenses
    ).toFixed(2);
    const etsyMoneyDeposit = (
      Math.abs(shop.salesSummaryDiff) -
      (Math.abs(shop.listingFeesDiff) +
        Math.abs(shop.marketingFeesDiff) +
        Math.abs(shop.ShippingFeesDiff))
    ).toFixed(2);
    const shippingExpenses = Math.abs(shop.ShippingFeesDiff);
    const totalSalesAmount = Math.abs(Number(shop.CSVSalesSummary));
    const totalRefundAmount = Math.abs(Number(shop.totalRefundsAmounts));
    const totalMarketingFees = Math.abs(shop.marketingFeesDiff);
    const totalFees = Math.abs(shop.listingFeesDiff);
    const shippingFees = Math.abs(shop.ShippingFeesDiff);
    const tpcExpenses = (
      Math.abs(shop.productTotalSum) +
      Math.abs(shop.productCustomCost) +
      Math.abs(shop.returnAndExchange)
    ).toFixed(2);

    const formattedStartDate = shop.StartDate ? new Date(shop.StartDate) : null;
    const formattedEndDate = shop.EndDate ? new Date(shop.EndDate) : null;

    if (formattedStartDate) {
      formattedStartDate.setDate(formattedStartDate.getDate());
    }
    if (formattedEndDate) {
      formattedEndDate.setDate(formattedEndDate.getDate());
    }

    const StartDateFormat = formattedStartDate
      ? formattedStartDate.toISOString().split("T")[0]
      : "";
    const EndDateFormat = formattedEndDate
      ? formattedEndDate.toISOString().split("T")[0]
      : "";

    return {
      shopExpenses,
      totalProfit,
      etsyMoneyDeposit,
      shippingExpenses,
      totalSalesAmount,
      totalRefundAmount,
      totalMarketingFees,
      totalFees,
      shippingFees,
      tpcExpenses,
      expenses,
      StartDateFormat,
      EndDateFormat,
    };
  };

  const calculateExpenses = (shop, start, end) => {
    const userStart = start ? new Date(start) : null;
    const userEnd = end ? new Date(end) : null;

    return shopExpensesData
      .filter((expense) => {
        return expense.shop === shop;
      })
      .reduce((total, expense) => {
        const expenseStart = new Date(expense.startDate);
        const expenseEnd = new Date(expense.endDate);

        if (!userStart || !userEnd) {
          return total + Number(expense.amount);
        }
        if (expenseEnd < userStart || expenseStart > userEnd) {
          return total;
        }

        const overlapStart =
          expenseStart < userStart ? userStart : expenseStart;
        const overlapEnd = expenseEnd > userEnd ? userEnd : expenseEnd;

        const totalDays =
          Math.ceil((expenseEnd - expenseStart) / (1000 * 3600 * 24)) + 1;
        const overlapDays =
          Math.ceil((overlapEnd - overlapStart) / (1000 * 3600 * 24)) + 1;

        if (overlapDays < 0) {
          return total;
        }

        const dailyCost = Number(expense.amount) / totalDays;
        const partialCost = dailyCost * overlapDays;

        return total + partialCost;
      }, 0);
  };

  const ShopData = () => {
    const formatCurrency = (value) =>
      Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

    const [selectedMetrics, setSelectedMetrics] = useState(["totalProfit"]);

    const metricOptions = [
      { field: "totalProfit", headerName: "Kar/Zarar" },
      { field: "etsyMoneyDeposit", headerName: "Etsy Yatması Beklenen Para" },
      { field: "totalSales", headerName: "Toplam Satış" },
      { field: "PaymentFee", headerName: "Etsy Yatan Para" },
      { field: "CSVSalesSummary", headerName: "Toplam Satış Adedi" },
      { field: "totalRefundsAmounts", headerName: "İadeler" },
      { field: "listingFees", headerName: "Liste Ücretleri" },
      { field: "marketingFees", headerName: "Pazarlama Ücretleri" },
      { field: "ShippingFees", headerName: "Kargo Ücretleri" },
      { field: "productTotalSum", headerName: "Toplam Ürün Tutarı CH" },
      { field: "productCustomCost", headerName: "Ürün Custom Maliyeti CH" },
      { field: "returnAndExchange", headerName: "İade ve Değişim CH" },
      { field: "ShippingFeesCSV", headerName: "Kargo Gider CH" },
      { field: "expenses", headerName: "Diğer Giderler" },
    ];

    // Mağazalar listesini çıkarma
    const Allshops = ["all", ...new Set(data.map((item) => item.SelectedShop))];

    const filteredData = Array.from(
      new Map(
        data
          .filter((item) => {
            const itemDate = new Date(item.StartDate);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;

            const matchesShop =
              selectedShop === "all" || item.SelectedShop === selectedShop;
            const matchesDate =
              (!start || itemDate >= start) && (!end || itemDate <= end);

            return matchesShop && matchesDate;
          })
          .map((item) => [item.ID, { ...item }])
      ).values()
    ).map((item) => ({
      ...item,
      ...calculateMetrics(item, startDate, endDate, userEarnings),
    }));

    const finalData = filteredData.reduce((acc, row, index) => {
      if (index === 0) {
        acc.push({
          ...row,
          listingFees: row.listingFees,
        });
      } else {
        const previousValue = acc[index - 1].listingFees;
        const newListingFees = row.listingFees - previousValue;

        acc.push({
          ...row,
          listingFees: newListingFees,
        });
      }
      return acc;
    }, []);

    const expenses =
      filteredData.reduce((sum, item) => sum + item.expenses, 0) /
      filteredData.length;

    const totalExpenses = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.expenses),
      0
    );

    const totalSum = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.totalSalesAmount),
      0
    );

    const shippingFees = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.shippingExpenses),
      0
    );
    const shippingFeesCustomHub = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.ShippingFeesCSV),
      0
    );
    const productCustomCost = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.productCustomCost),
      0
    );
    const totelFees = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.totalFees),
      0
    );
    const totalMarketing = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.totalMarketingFees),
      0
    );

    const etsyRealDeposit = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.PaymentFee),
      0
    );

    const totalProfitSum = filteredData.reduce((sum, item) => {
      const profit = Number(item.totalProfit); // totalProfit'i sayıya çeviriyoruz
      return !isNaN(profit) ? sum + profit : sum; // Eğer geçerli bir sayı değilse toplama dahil etme
    }, 0);

    const totalProfitViaUnit = totalProfitSum / totalSum;
    const totalMarketingViaUnit = totalMarketing / totalSum;
    const totalFeeViaUnit = totelFees / totalSum;
    const shippingFeeViaUnit = shippingFeesCustomHub / totalSum;
    const expenesViaUnit = totalExpenses / totalSum;

    const columns = [
      { field: "ID", headerName: "ID", width: 50, backgroundColor: "#5CB338" },
      { field: "Username", headerName: "Kullanıcı Adı", width: 70 },
      { field: "SelectedShop", headerName: "Mağaza", width: 130 },
      { field: "StartDateFormat", headerName: "Başlangıç Tarihi", width: 130 },
      {
        field: "EndDateFormat",
        headerName: "Bitiş Tarihi",
        width: 130,
      },
      {
        field: "totalProfit",
        headerName: "Kar/Zarar",
        width: 130,
        renderCell: (params) => (
          <Box
            sx={{
              backgroundColor: "#5CB338",
              color: "white",
              p: 1,
              borderRadius: 1,
            }}
          >
            {params.value}
          </Box>
        ),
      },
      { field: "PaymentFee", headerName: "Etsy Yatan para", width: 130 },
      {
        field: "CSVSalesSummary",
        headerName: "Toplam Satış Adedi",
        width: 160,
      },
      { field: "totalRefundsAmounts", headerName: "İadeler", width: 130 },
      { field: "ShippingFeesCSV", headerName: "Kargo Gider CH", width: 130 },
      {
        field: "productTotalSum",
        headerName: "Toplam Ürün Tutarı CH",
        width: 150,
      },
      { field: "expenses", headerName: "Diğer Giderler", width: 130 },

      {
        field: "etsyMoneyDeposit",
        headerName: "Etsy Yatması Beklenen Para",
        width: 130,
      },
      { field: "totalSales", headerName: "Toplam Satış", width: 130 },
      {
        field: "listingFeesABS",
        headerName: "Liste Ücretleri Girilen",
        width: 130,
      },
      {
        field: "listingFeesDiff",
        headerName: "Liste Ücretleri Haftalık",
        width: 130,
      },
      {
        field: "marketingFeesABS",
        headerName: "Pazarlama Ücretleri Girilen",
        width: 130,
      },
      {
        field: "marketingFeesDiff",
        headerName: "Pazarlama Ücretleri Haftalık",
        width: 130,
      },
      {
        field: "ShippingFeesABS",
        headerName: "Kargo Ücretleri Girilen",
        width: 130,
      },
      {
        field: "ShippingFeesDiff",
        headerName: "Kargo Ücretleri Haftalık",
        width: 130,
      },
      {
        field: "salesSummary",
        headerName: "Sales Summary Girilen",
        width: 130,
      },
      {
        field: "salesSummaryDiff",
        headerName: "Sales Summary Haftalık",
        width: 130,
      },

      {
        field: "productCustomCost",
        headerName: "Ürün Custom Maliyeti CH",
        width: 160,
      },
      {
        field: "returnAndExchange",
        headerName: "İade ve Değişim CH",
        width: 160,
      },
      { field: "piaceInput", headerName: "Adet Girişi", width: 130 },
    ];
    const COLORS = ["#1976d2", "#2e7d32", "#ed6c02", "#9c27b0", "#e91e63"];

    const MetricCard = ({ title, value }) => {
      let titleColor;
      if (title === "Toplam Brüt Kar/Zarar") titleColor = "#5CB338";
      else if (
        title === "Adet Başına Kargo" ||
        title === "Adet Başına Diğer Gider Masrafları" ||
        title === "Adet Başına Fees" ||
        title === "Adet Başına Marketing" ||
        title === "Adet Başına Kar/Zarar"
      )
        titleColor = "#7695FF";
      else titleColor = "#FADFA1";

      return (
        <Card>
          <CardContent style={{ backgroundColor: titleColor }}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="h4">{value}</Typography>
          </CardContent>
        </Card>
      );
    };

    const exportToExcel = () => {
      const headers = columns.map((col) => col.headerName);

      const excelData = filteredData.map((row) =>
        columns.reduce((acc, col) => {
          acc[col.headerName] = row[col.field];
          return acc;
        }, {})
      );

      const worksheet = XLSX.utils.json_to_sheet(excelData, {
        header: headers,
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(data, "exported_data.xlsx");
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
        <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              {/* Filters */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel>Mağaza Seç</InputLabel>
                          <Select
                            value={selectedShop}
                            label="Mağaza Seç"
                            onChange={(e) => setSelectedShop(e.target.value)}
                          >
                            {Allshops.map((shop) => (
                              <MenuItem key={shop} value={shop}>
                                {shop === "all" ? "Tüm Mağazalar" : shop}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <DatePicker
                          label="Başlangıç Tarihi"
                          value={startDate}
                          onChange={(newValue) => setStartDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          format="dd/MM/yyyy"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <DatePicker
                          label="Bitiş Tarihi"
                          value={endDate}
                          onChange={(newValue) => setEndDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          format="dd/MM/yyyy"
                          minDate={startDate}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {/* Summary Cards - First Row */}
              <Grid item xs={12} md={3} sx>
                <MetricCard
                  className="custom-card"
                  title="Toplam Brüt Kar/Zarar"
                  value={formatCurrency(totalProfitSum)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Etsy Yatan Para"
                  value={formatCurrency(
                    etsyRealDeposit.toFixed(2)
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Etsy Yatması Beklenen Para"
                  value={formatCurrency(
                    filteredData.reduce(
                      (sum, item) => sum + Number(item.etsyMoneyDeposit),
                      0
                    )
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Kargo Giderleri"
                  value={formatCurrency(shippingFeesCustomHub.toFixed(2))}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="ETSY Toplam Satış Miktarı (Adet)"
                  value={filteredData.reduce(
                    (sum, item) => sum + item.totalSalesAmount,
                    0
                  )}
                />
              </Grid>

              {/* Summary Cards - Second Row */}
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="ETSY Toplam İade Miktarı (Adet)"
                  value={filteredData
                    .reduce((sum, item) => sum + item.totalRefundAmount, 0)
                    .toFixed(0)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Reklam Giderleri"
                  value={formatCurrency(
                    filteredData.reduce(
                      (sum, item) => sum + Math.abs(item.totalMarketingFees),
                      0
                    )
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="ETSY Masrafları"
                  value={formatCurrency(
                    filteredData.reduce(
                      (sum, item) => sum + Math.abs(item.totalFees),
                      0
                    )
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="TPC Giderleri"
                  value={formatCurrency(
                    filteredData.reduce(
                      (sum, item) => sum + Math.abs(item.tpcExpenses),
                      0
                    )
                  )}
                />
              </Grid>

              {/* Unit Metrics */}
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Diğer Giderler"
                  value={formatCurrency(totalExpenses.toFixed(2))}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Adet Başına Marketing"
                  value={"$" + totalMarketingViaUnit.toFixed(2)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Adet Başına Fees"
                  value={"$" + totalFeeViaUnit.toFixed(2)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Adet Başına Kargo"
                  value={"$" + shippingFeeViaUnit.toFixed(2)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Adet Başına Kar/Zarar"
                  value={"$" + totalProfitViaUnit.toFixed(2)}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Adet Başına Diğer Gider Masrafları"
                  value={"$" + expenesViaUnit.toFixed(2)}
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ display: "hidden" }}></Grid>
              {/* Charts */}

              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                          <InputLabel>Gösterilecek Veri</InputLabel>
                          <Select
                            multiple
                            value={selectedMetrics}
                            label="Gösterilecek Veriler"
                            onChange={(e) => setSelectedMetrics(e.target.value)}
                          >
                            {metricOptions.map((option) => (
                              <MenuItem key={option.field} value={option.field}>
                                {option.headerName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {/* Dynamic Bar Chart */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Veri Karşılaştırma Grafiği
                    </Typography>
                    <Box sx={{ height: 400 }}>
                      <ResponsiveContainer>
                        <LineChart
                          data={filteredData
                            .sort(
                              (a, b) =>
                                new Date(a.StartDate) - new Date(b.StartDate)
                            )
                            .map((item) => ({
                              ...item,
                              ...selectedMetrics.reduce(
                                (acc, metric) => ({
                                  ...acc,
                                  [metric]: Number(item[metric]) || 0,
                                }),
                                {}
                              ),
                            }))}
                          margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="StartDate"
                            tickFormatter={(date) =>
                              new Date(date).toLocaleDateString("tr-TR")
                            }
                            interval="preserveStartEnd"
                          />
                          <YAxis />
                          <Tooltip
                            formatter={(value, name, props) => {
                              if (typeof value === "number") {
                                return name.includes("Fee") ||
                                  name.includes("Price") ||
                                  name.includes("Cost") ||
                                  name.includes("Profit")
                                  ? formatCurrency(value)
                                  : value.toLocaleString();
                              }
                              return value;
                            }}
                            labelFormatter={(label) =>
                              new Date(label).toLocaleDateString("tr-TR")
                            }
                          />
                          <Legend />
                          {selectedMetrics.map((metric, index) => {
                            const metricOption = metricOptions.find(
                              (option) => option.field === metric
                            );
                            return (
                              <Line
                                key={metric}
                                type="linear"
                                dataKey={metric}
                                name={metricOption?.headerName}
                                stroke={COLORS[index % COLORS.length]} // Renk döngüsü için modulo kullanımı
                                strokeWidth={2}
                                dot={{ r: 4, strokeWidth: 1, fill: "#fff" }}
                                activeDot={{ r: 6, strokeWidth: 1 }}
                              />
                            );
                          })}
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card sx={{ mb: 0 }}>
                  <CardContent>
                    <Typography variant="h6" color="primary">
                      Toplam Çalışma Süresi: {totalWorkHours} saat
                    </Typography>

                    {(user.Admin === 1 || user.Username === selectedUser) && (
                      <Typography variant="h6" color="secondary">
                        Toplam Maliyet: {totalCalculations.totalCost} USD
                        <Typography
                          component="span"
                          sx={{
                            color: "warning.main", // sarı renk
                            fontSize: "0.8em", // ana yazıdan daha küçük
                            marginLeft: 1, // sol boşluk
                          }}
                        >
                          (ARGE: {totalCalculations.argeCost} USD, Diğer:{" "}
                          {totalCalculations.otherCost} USD)
                        </Typography>
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Günlük Efor Dağılımı
                    </Typography>
                    <Box sx={{ height: 300 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={dailyEfforts}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="hours"
                            stroke="#1976d2"
                            name="Saat"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Görev Dağılımı
                    </Typography>
                    <Box sx={{ height: 300 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={taskDistribution}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            label
                          >
                            {/* Her dilim için farklı renk vermek */}
                            {taskDistribution.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Data Table */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Detaylı Veri
                    </Typography>
                    <Box sx={{ height: 400 }}>
                      <DataGrid
                        rows={filteredData}
                        getRowId={(row) => row.ID}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50]}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        disableSelectionOnClick
                      />
                    </Box>
                  </CardContent>
                </Card>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={exportToExcel}
                >
                  Excel'e Aktar
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </LocalizationProvider>
    );
  };

  const CompareShops = () => {
    const formatCurrency = (value) =>
      Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

    const [dateRange, setDateRange] = useState({
      start: null,
      end: null,
    });

    const metricOptions = [
      { field: "totalProfit", headerName: "Kar/Zarar" },
      { field: "etsyMoneyDeposit", headerName: "Etsy Yatması Beklenen Para" },
      { field: "totalSales", headerName: "Toplam Satış" },
      { field: "PaymentFee", headerName: "Etsy Yatan Para" },
      { field: "CSVSalesSummary", headerName: "Toplam Satış Adedi" },
      { field: "totalRefundsAmounts", headerName: "İadeler" },
      { field: "listingFees", headerName: "Liste Ücretleri" },
      { field: "marketingFees", headerName: "Pazarlama Ücretleri" },
      { field: "ShippingFees", headerName: "Kargo Ücretleri" },
      { field: "productTotalSum", headerName: "Toplam Ürün Tutarı CH" },
      { field: "productCustomCost", headerName: "Ürün Custom Maliyeti CH" },
      { field: "returnAndExchange", headerName: "İade ve Değişim CH" },
      { field: "ShippingFeesCSV", headerName: "Kargo Gider CH" },
      { field: "expenses", headerName: "Diğer Giderler" },
    ];

    // Mağazalar listesini çıkarma
    const Allshops = ["all", ...new Set(data.map((item) => item.SelectedShop))];

    const [selectedShops, setSelectedShops] = useState([]);
    const [selectedComparisonMetrics, setSelectedComparisonMetrics] = useState([
      "totalProfit",
    ]);

    const filteredData = Array.from(
      new Map(
        data
          .filter((item) => {
            const itemDate = new Date(item.StartDate);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;

            const matchesShop =
              selectedShop === "all" || item.SelectedShop === selectedShop;
            const matchesDate =
              (!start || itemDate >= start) && (!end || itemDate <= end);

            return matchesShop && matchesDate;
          })
          .map((item) => [item.ID, { ...item }])
      ).values()
    ).map((item) => ({
      ...item,
      ...calculateMetrics(item, startDate, endDate, userEarnings),
    }));
    console.log(filteredData);

    const expenses =
      filteredData.reduce((sum, item) => sum + item.expenses, 0) /
      filteredData.length;

    const totalExpenses = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.expenses),
      0
    );

    const totalSum = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.totalSalesAmount),
      0
    );

    const shippingFees = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.shippingExpenses),
      0
    );
    const shippingFeesCustomHub = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.ShippingFeesCSV),
      0
    );
    const productCustomCost = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.productCustomCost),
      0
    );
    const totelFees = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.totalFees),
      0
    );
    const totalMarketing = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.totalMarketingFees),
      0
    );

    const returnAndExchange = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.returnAndExchange),
      0
    );
    const productTotalSum = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.productTotalSum),
      0
    );
    const totalEtsyProfit = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.PaymentFee),
      0
    );

    const totalProfit =
      totalEtsyProfit -
      Math.abs(shippingFeesCustomHub) -
      Math.abs(productCustomCost) -
      Math.abs(returnAndExchange) -
      Math.abs(productTotalSum) -
      Math.abs(expenses);

    const totalProfitSum = filteredData.reduce((sum, item) => {
      const profit = Number(item.totalProfit);
      return !isNaN(profit) ? sum + profit : sum;
    }, 0);

    const totalProfitViaUnit = totalProfitSum / totalSum;
    const totalMarketingViaUnit = totalMarketing / totalSum;
    const totalFeeViaUnit = totelFees / totalSum;
    const shippingFeeViaUnit = shippingFeesCustomHub / totalSum;
    const expenesViaUnit = totalExpenses / totalSum;

    const columns = [
      { field: "ID", headerName: "ID", width: 50, backgroundColor: "#5CB338" },
      { field: "Username", headerName: "Kullanıcı Adı", width: 70 },
      { field: "SelectedShop", headerName: "Mağaza", width: 130 },
      { field: "StartDateFormat", headerName: "Başlangıç Tarihi", width: 130 },
      {
        field: "EndDateFormat",
        headerName: "Bitiş Tarihi",
        width: 130,
      },
      {
        field: "totalProfit",
        headerName: "Kar/Zarar",
        width: 130,
        renderCell: (params) => (
          <Box
            sx={{
              backgroundColor: "#5CB338",
              color: "white",
              p: 1,
              borderRadius: 1,
            }}
          >
            {params.value}
          </Box>
        ),
      },
      { field: "PaymentFee", headerName: "Etsy Yatan para", width: 130 },
      {
        field: "CSVSalesSummary",
        headerName: "Toplam Satış Adedi",
        width: 160,
      },
      { field: "totalRefundsAmounts", headerName: "İadeler", width: 130 },
      { field: "ShippingFeesCSV", headerName: "Kargo Gider CH", width: 130 },
      {
        field: "productTotalSum",
        headerName: "Toplam Ürün Tutarı CH",
        width: 150,
      },
      { field: "expenses", headerName: "Diğer Giderler", width: 130 },

      {
        field: "etsyMoneyDeposit",
        headerName: "Etsy Yatması Beklenen Para",
        width: 130,
      },
      { field: "totalSales", headerName: "Toplam Satış", width: 130 },
      {
        field: "listingFeesABS",
        headerName: "Liste Ücretleri Girilen",
        width: 130,
      },
      {
        field: "listingFeesDiff",
        headerName: "Liste Ücretleri Haftalık",
        width: 130,
      },
      {
        field: "marketingFeesABS",
        headerName: "Pazarlama Ücretleri Girilen",
        width: 130,
      },
      {
        field: "marketingFeesDiff",
        headerName: "Pazarlama Ücretleri Haftalık",
        width: 130,
      },
      {
        field: "ShippingFeesABS",
        headerName: "Kargo Ücretleri Girilen",
        width: 130,
      },
      {
        field: "ShippingFeesDiff",
        headerName: "Kargo Ücretleri Haftalık",
        width: 130,
      },
      {
        field: "salesSummary",
        headerName: "Sales Summary Girilen",
        width: 130,
      },
      {
        field: "salesSummaryDiff",
        headerName: "Sales Summary Haftalık",
        width: 130,
      },

      {
        field: "productCustomCost",
        headerName: "Ürün Custom Maliyeti CH",
        width: 160,
      },
      {
        field: "returnAndExchange",
        headerName: "İade ve Değişim CH",
        width: 160,
      },
      { field: "piaceInput", headerName: "Adet Girişi", width: 130 },
    ];
    const COLORS = ["#1976d2", "#2e7d32", "#ed6c02", "#9c27b0", "#e91e63"];

    const MetricCard = ({ title, value }) => {
      let titleColor;
      if (title === "Toplam Brüt Kar/Zarar") titleColor = "#5CB338";
      else if (
        title === "Adet Başına Kargo" ||
        title === "Adet Başına Diğer Gider Masrafları" ||
        title === "Adet Başına Fees" ||
        title === "Adet Başına Marketing" ||
        title === "Adet Başına Kar/Zarar"
      )
        titleColor = "#7695FF";
      else titleColor = "#FADFA1";

      return (
        <Card>
          <CardContent style={{ backgroundColor: titleColor }}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="h4">{value}</Typography>
          </CardContent>
        </Card>
      );
    };

    const exportToExcel = () => {
      const headers = columns.map((col) => col.headerName);

      const excelData = filteredData.map((row) =>
        columns.reduce((acc, col) => {
          acc[col.headerName] = row[col.field];
          return acc;
        }, {})
      );

      const worksheet = XLSX.utils.json_to_sheet(excelData, {
        header: headers,
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(data, "exported_data.xlsx");
    };


    const comparisonData = useMemo(() => {
      return selectedShops.map((shop) => {
        const shopData = data
          .filter((item) => item.SelectedShop === shop)
          .map((item) => ({
            ...item,
            ...calculateMetrics(
              item,
              dateRange.start,
              dateRange.end,
              userEarnings
            ),
          }));

        const aggregated = shopData.reduce((acc, row) => {
          acc.totalProfit = (acc.totalProfit || 0) + Number(row.totalProfit);
          acc.etsyMoneyDeposit =
            (acc.etsyMoneyDeposit || 0) + Number(row.etsyMoneyDeposit);
          acc.expenses = (acc.expenses || 0) + Number(row.expenses);

          return acc;
        }, {});

        return {
          shop,
          ...aggregated,
        };
      });
    }, [selectedShops, data, dateRange, userEarnings]);
    // Karşılaştırma grafik verisi
    const chartData = useMemo(() => {
      return metricOptions
        .filter((option) => selectedComparisonMetrics.includes(option.field))
        .map((option) => {
          const metricEntry = { metric: option.headerName };
          comparisonData.forEach((shopData) => {
            metricEntry[shopData.shop] = Number(shopData[option.field]) || 0;
          });
          return metricEntry;
        });
    }, [comparisonData, selectedComparisonMetrics]);

    // Yeni sütunlar
    const comparisonColumns = [
      {
        field: "metric",
        headerName: "Metrik",
        width: 200,
        renderCell: (params) => (
          <strong style={{ color: "#1976d2" }}>{params.value}</strong>
        ),
      },
      ...selectedShops.map((shop) => ({
        field: shop,
        headerName: shop,
        width: 180,
        renderCell: (params) => (
          <span style={{ color: "#2e7d32" }}>
            {typeof params.value === "number"
              ? formatCurrency(params.value) // formatCurrency fonksiyonunu kullan
              : params.value}
          </span>
        ),
      })),
    ];

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
        <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel>
                            Karşılaştırma İçin Mağazalar Seç
                          </InputLabel>
                          <Select
                            multiple
                            value={selectedShops}
                            onChange={(e) => setSelectedShops(e.target.value)}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {Allshops.filter((shop) => shop !== "all").map(
                              (shop) => (
                                <MenuItem key={shop} value={shop}>
                                  <Checkbox
                                    checked={selectedShops.indexOf(shop) > -1}
                                  />
                                  <ListItemText primary={shop} />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel>Karşılaştırma Metrikleri</InputLabel>
                          <Select
                            multiple
                            value={selectedComparisonMetrics}
                            onChange={(e) =>
                              setSelectedComparisonMetrics(e.target.value)
                            }
                            renderValue={(selected) =>
                              selected
                                .map(
                                  (s) =>
                                    metricOptions.find((m) => m.field === s)
                                      ?.headerName
                                )
                                .join(", ")
                            }
                          >
                            {metricOptions.map((option) => (
                              <MenuItem key={option.field} value={option.field}>
                                <Checkbox
                                  checked={selectedComparisonMetrics.includes(
                                    option.field
                                  )}
                                />
                                <ListItemText primary={option.headerName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <DatePicker
                          label="Başlangıç Tarihi"
                          value={dateRange.start}
                          fullWidth
                          onChange={(newValue) =>
                            setDateRange((prev) => ({
                              ...prev,
                              start: newValue,
                            }))
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <DatePicker
                          label="Bitiş Tarihi"
                          value={dateRange.end}
                          fullWidth
                          onChange={(newValue) =>
                            setDateRange((prev) => ({ ...prev, end: newValue }))
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {/* Karşılaştırmalı Grafik */}
              {selectedShops.length > 0 && (
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Mağaza Karşılaştırma Grafiği
                      </Typography>
                      <Box sx={{ height: 500 }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            data={chartData}
                            margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="metric"
                              angle={-45}
                              textAnchor="end"
                              tick={{ fontSize: 12 }}
                            />
                            <YAxis
                              tickFormatter={(value) =>
                                value.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                  maximumFractionDigits: 0,
                                })
                              }
                            />
                            <Tooltip
                              formatter={(value) =>
                                Number(value).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                  minimumFractionDigits: 2,
                                })
                              }
                            />
                            <Legend />
                            {selectedShops.map((shop, index) => (
                              <Bar
                                key={shop}
                                dataKey={shop}
                                name={shop}
                                fill={COLORS[index % COLORS.length]}
                                barSize={30}
                              />
                            ))}
                          </BarChart>
                        </ResponsiveContainer>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {/* Karşılaştırmalı Tablo */}
              {selectedShops.length > 0 && (
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Detaylı Karşılaştırma Tablosu
                      </Typography>
                      <Box sx={{ height: 400 }}>
                        <DataGrid
                          rows={chartData}
                          columns={comparisonColumns}
                          pageSize={5}
                          rowsPerPageOptions={[5, 10, 20]}
                          components={{
                            Toolbar: GridToolbar,
                          }}
                          getRowId={(row) => row.metric}
                          disableSelectionOnClick
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {/* ... Mevcut diğer bileşenleriniz ... */}
            </Grid>
          </Container>
        </Box>
      </LocalizationProvider>
    );
  };

  const calculateTimeSummary = (timeSummary) => {
    if (!timeSummary) return 0;

    const [hours, minutes] = timeSummary.split(":").map(Number);
    return hours + minutes / 60;
  };

  const calculateUserEarnings = () => {
    const userEarnings = {};

    userData.forEach((record) => {
      if (!userEarnings[record.UserName]) {
        userEarnings[record.UserName] = {
          argeHours: 0,
          argeEarnings: 0,
          otherHours: 0,
          otherEarnings: 0,
          totalHours: 0,
          totalEarnings: 0,
          shops: {},
        };
      }

      // TimeSummary kolonundaki saat ve dakikaları işle
      const [hours, minutes] = record.TimeSummary.split(":").map(Number);
      const durationHours = hours + minutes / 60;

      // Ücret bilgilerini bul
      const userFee = userEffortsFees.find(
        (fee) => fee.userId === record.UserID
      );
      if (!userFee) return;

      // ARGE veya normal mağaza kontrolü
      if (record.Shop === "Arge") {
        const rate = parseFloat(userFee.effortType2Fee) || 0;
        userEarnings[record.UserName].argeHours += durationHours;
        userEarnings[record.UserName].argeEarnings += durationHours * rate;
      } else {
        const rate = parseFloat(userFee.effortType1Fee) || 0;
        userEarnings[record.UserName].otherHours += durationHours;
        userEarnings[record.UserName].otherEarnings += durationHours * rate;

        // Mağaza bazlı kazanç
        if (!userEarnings[record.UserName].shops[record.Shop]) {
          userEarnings[record.UserName].shops[record.Shop] = {
            hours: 0,
            earnings: 0,
          };
        }
        userEarnings[record.UserName].shops[record.Shop].hours += durationHours;
        userEarnings[record.UserName].shops[record.Shop].earnings +=
          durationHours * rate;
      }

      // Toplamları güncelle
      userEarnings[record.UserName].totalHours += durationHours;
      userEarnings[record.UserName].totalEarnings =
        userEarnings[record.UserName].argeEarnings +
        userEarnings[record.UserName].otherEarnings;
    });

    return userEarnings;
  };

  const userEarnings = calculateUserEarnings();

  // Filter data based on date range
  const filteredEarnings = React.useMemo(() => {
    if (!startDate && !endDate) return userEarnings;

    const filtered = {};
    userData.forEach((record) => {
      const recordDate = new Date(record.EffortDate);
      if (
        (!startDate || recordDate >= startDate) &&
        (!endDate || recordDate <= endDate)
      ) {
        // Include this record in calculations
        const userName = record.UserName;
        if (!filtered[userName]) {
          filtered[userName] = {
            argeHours: 0,
            argeEarnings: 0,
            otherHours: 0,
            otherEarnings: 0,
            totalHours: 0,
            totalEarnings: 0,
          };
        }

        const hours =
          parseFloat(record.TimeSummary.split(":")[0]) +
          parseFloat(record.TimeSummary.split(":")[1]) / 60;

        const userFee = userEffortsFees.find(
          (fee) => fee.userId === record.UserID
        );
        if (!userFee) return;

        if (record.Shop === "Arge") {
          const argeRate = parseFloat(userFee.effortType2Fee) || 0;
          filtered[userName].argeHours += hours;
          filtered[userName].argeEarnings += hours * argeRate;
        } else {
          const shopRate = parseFloat(userFee.effortType1Fee) || 0;
          filtered[userName].otherHours += hours;
          filtered[userName].otherEarnings += hours * shopRate;
        }

        filtered[userName].totalHours += hours;
        filtered[userName].totalEarnings =
          filtered[userName].argeEarnings + filtered[userName].otherEarnings;
      }
    });

    return filtered;
  }, [userData, userEffortsFees, startDate, endDate]);

  const exportToExcel = () => {
    const data = Object.entries(filteredEarnings)
      .filter(
        ([userName]) => selectedUser === "all" || userName === selectedUser
      )
      .map(([userName, earnings]) => ({
        Kullanıcı: userName,
        "Arge Saatleri": earnings.argeHours.toFixed(2),
        "Arge Kazancı": `$${earnings.argeEarnings.toFixed(2)}`,
        "Diğer Saatler": earnings.otherHours.toFixed(2),
        "Diğer Kazanç": `$${earnings.otherEarnings.toFixed(2)}`,
        "Toplam Saat": earnings.totalHours.toFixed(2),
        "Toplam Kazanç": `$${earnings.totalEarnings.toFixed(2)}`,
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);

    // Tüm kolonları al
    const columnsArray = Object.keys(data[0]);

    // Her kolonun genişliğini ayarla
    const columnWidths = columnsArray.map((col) => ({ wch: col.length + 2 }));
    worksheet["!cols"] = columnWidths;

    // Style özelliklerini tanımla
    const headerStyle = {
      fill: {
        fgColor: { rgb: "90EE90" }, // Açık yeşil renk
        type: "pattern",
        patternType: "solid",
      },
      font: {
        bold: true,
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    // Her kolon başlığına style uygula
    columnsArray.forEach((col, idx) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: idx });
      worksheet[cellRef].s = headerStyle;
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Kazanç Özeti");

    // Dosyayı kaydet
    XLSX.writeFile(workbook, "Kazanç_Özeti.xlsx");
  };

  const UserEarningsScreen = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div style={{ padding: "24px" }}>
          <Grid container spacing={3}>
            {/* Date Range Selection */}
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Tarih Aralığı
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        label="Başlangıç Tarihi"
                        value={startDate}
                        onChange={setStartDate}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        label="Bitiş Tarihi"
                        value={endDate}
                        onChange={setEndDate}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* User Filter */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Kullanıcı Filtresi
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel>Kullanıcı Seç</InputLabel>
                    <Select
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                      label="Kullanıcı Seç"
                    >
                      <MenuItem value="all">Tüm Kullanıcılar</MenuItem>
                      {Object.keys(filteredEarnings).map((userName) => (
                        <MenuItem key={userName} value={userName}>
                          {userName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>

            {/* Earnings Table */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Kazanç Özeti
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: "16px" }}
                    onClick={exportToExcel}
                  >
                    Excel'e Aktar
                  </Button>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Kullanıcı</TableCell>
                          <TableCell>Arge Saatleri</TableCell>
                          <TableCell>Arge Kazancı</TableCell>
                          <TableCell>Diğer Saatler</TableCell>
                          <TableCell>Diğer Kazanç</TableCell>
                          <TableCell>Toplam Saat</TableCell>
                          <TableCell>Toplam Kazanç</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(filteredEarnings)
                          .filter(
                            ([userName]) =>
                              selectedUser === "all" ||
                              userName === selectedUser
                          )
                          .map(([userName, earnings]) => (
                            <TableRow key={userName}>
                              <TableCell>{userName}</TableCell>
                              <TableCell>
                                {earnings.argeHours.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                ${earnings.argeEarnings.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {earnings.otherHours.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                ${earnings.otherEarnings.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {earnings.totalHours.toFixed(2)}
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                ${earnings.totalEarnings.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}

                        {/* Alt toplamlar */}
                        <TableRow
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5",
                          }}
                        >
                          <TableCell>Toplam</TableCell>
                          <TableCell>
                            {Object.values(filteredEarnings)
                              .reduce(
                                (sum, earnings) => sum + earnings.argeHours,
                                0
                              )
                              .toFixed(2)}
                          </TableCell>
                          <TableCell>
                            $
                            {Object.values(filteredEarnings)
                              .reduce(
                                (sum, earnings) => sum + earnings.argeEarnings,
                                0
                              )
                              .toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Object.values(filteredEarnings)
                              .reduce(
                                (sum, earnings) => sum + earnings.otherHours,
                                0
                              )
                              .toFixed(2)}
                          </TableCell>
                          <TableCell>
                            $
                            {Object.values(filteredEarnings)
                              .reduce(
                                (sum, earnings) => sum + earnings.otherEarnings,
                                0
                              )
                              .toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Object.values(filteredEarnings)
                              .reduce(
                                (sum, earnings) => sum + earnings.totalHours,
                                0
                              )
                              .toFixed(2)}
                          </TableCell>
                          <TableCell>
                            $
                            {Object.values(filteredEarnings)
                              .reduce(
                                (sum, earnings) => sum + earnings.totalEarnings,
                                0
                              )
                              .toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </LocalizationProvider>
    );
  };

  const CustomScreenShotControl = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [screenshots, setScreenshots] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage] = useState(60); // Her sayfada gösterilecek görüntü sayısı

    // Chunk processing function to handle large binary data
    const processBase64Chunk = (buffer) => {
        if (!buffer || !buffer.data) return null;
        
        try {
            // Convert to Uint8Array in smaller chunks
            const chunk = new Uint8Array(buffer.data);
            let binary = '';
            const chunkSize = 1024;
            
            for (let i = 0; i < chunk.length; i += chunkSize) {
                const subChunk = chunk.slice(i, i + chunkSize);
                binary += String.fromCharCode.apply(null, subChunk);
            }
            
            return `data:image/jpeg;base64,${btoa(binary)}`;
        } catch (error) {
            console.error("Error processing image chunk:", error);
            return null;
        }
    };

    const handleFetchScreenshots = async () => {
        try {
            setLoading(true);
            setError("");
            setPage(0); // Reset page when fetching new data

            const startDateTime = startDate.format("YYYY-MM-DD HH:mm:ss");
            const endDateTime = endDate.format("YYYY-MM-DD HH:mm:ss");

            const response = await axios.post(`${path}/api/GetScreenShots`, {
                startDate: startDateTime,
                endDate: endDateTime,
                userName: selectedUser,
            });

            // Process screenshots in chunks
            const processedScreenshots = response.data.map(screenshot => ({
                ...screenshot,
                Base64Image: processBase64Chunk(screenshot.Base64Image)
            }));

            setScreenshots(processedScreenshots);
        } catch (err) {
            setError("Ekran görüntüleri yüklenirken bir hata oluştu.");
            console.error("Error fetching screenshots:", err);
        } finally {
            setLoading(false);
        }
    };

    // Calculate pagination
    const pageCount = Math.ceil(screenshots.length / itemsPerPage);
    const currentPageItems = screenshots.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                        flexWrap: "wrap",
                        alignItems: "center",
                        mb: 3,
                    }}
                >
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel>Kullanıcı</InputLabel>
                        <Select
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}
                            label="Kullanıcı"
                        >
                            {users.map((user) => (
                                <MenuItem key={user} value={user}>
                                    {user}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Başlangıç Tarihi"
                            value={startDate}
                            onChange={setStartDate}
                            sx={{ minWidth: 250 }}
                            ampm={false}
                        />
                        <DateTimePicker
                            label="Bitiş Tarihi"
                            value={endDate}
                            onChange={setEndDate}
                            sx={{ minWidth: 250 }}
                            ampm={false}
                        />
                    </LocalizationProvider>

                    <Button
                        variant="contained"
                        onClick={handleFetchScreenshots}
                        sx={{ height: 56 }}
                    >
                        Ekran Görüntülerini Getir
                    </Button>
                </Box>

                {screenshots.length > 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                        <Typography>
                            Toplam: {screenshots.length} görüntü
                        </Typography>
                        <Pagination
                            count={pageCount}
                            page={page + 1}
                            onChange={(e, newPage) => setPage(newPage - 1)}
                            color="primary"
                        />
                    </Box>
                )}
            </Paper>

            <Box sx={{ mt: 4 }}>
                {loading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height={400}
                    >
                        <Typography sx={{ ml: 2 }}>Yükleniyor...</Typography>
                    </Box>
                ) : error ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="error.main"
                    >
                        {error}
                    </Box>
                ) : screenshots.length === 0 ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="text.secondary"
                    >
                        Bu zaman aralığında ekran görüntüsü bulunmamaktadır.
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            gap: 3,
                            justifyItems: "center",
                            maxHeight: "800px",
                            overflow: "auto",
                            p: 2,
                        }}
                    >
                        {currentPageItems.map((screenshot, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: "100%",
                                    maxWidth: 1400,
                                    border: "1px solid",
                                    borderColor: "divider",
                                    borderRadius: 2,
                                    p: 2,
                                    boxShadow: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        maxHeight: 900,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                    }}
                                >
                                    {screenshot.Base64Image ? (
                                        <Box
                                            component="img"
                                            src={screenshot.Base64Image}
                                            alt={`Ekran Görüntüsü ${index + 1}`}
                                            sx={{
                                                maxWidth: "100%",
                                                height: "auto",
                                                objectFit: "contain",
                                            }}
                                            onError={(e) => {
                                                e.target.src = "/api/placeholder/400/300";
                                            }}
                                        />
                                    ) : (
                                        <Typography color="text.secondary">
                                            Görüntü yüklenemedi
                                        </Typography>
                                    )}
                                </Box>
                                <Typography variant="caption" color="text.secondary" mt={1}>
                                    {dayjs(screenshot.UploadDate).format("DD.MM.YYYY HH:mm:ss")}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // Artık sabit yükseklik kullanmıyoruz.
          // height: "100vh",
          minHeight: "100vh", // sayfa boyu kadar arkaplanın uzaması için
          bgcolor: "rgb(180, 190, 190)",
          p: 2,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            style={{ backgroundColor: "#405D72" }}
          >
            <Toolbar style={{ backgroundColor: "#405D72" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={[
                  {
                    mr: 2,
                  },
                  open && { display: "none" },
                ]}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Kullanıcı Raporları
              </Typography>

              <IconButton
                onClick={() => navigate("/")}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  padding: "5px 15px",
                  borderRadius: "25px",
                  backgroundColor: "#fff",
                  ml: "auto",
                  "&:hover": {
                    backgroundColor: "#ccc",
                  },
                }}
              >
                <Avatar
                  src="/Images/Shops/Nile/DesignCluster1/Design4/Design4.svg"
                  alt="Ana Menü"
                  sx={{ width: 50, height: 50 }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  Ana Menü
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                backgroundColor: "#DDE7F2",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <Toolbar>
              <IconButton onClick={handleDrawerClose}>
                {<ChevronLeftIcon />}
              </IconButton>
            </Toolbar>
            <Divider />
            <List>
              {[
                "Kullanıcı Raporları",
                "Shop Raporları",
                "Karşılaştırmalı Raporlar",
                "Kullanıcı Ücretleri",
                "Kullanıcı Takip",
              ].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton onClick={() => handleMenuClick(text)}>
                    <ListItemIcon>
                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3, maxHeight: "100vh" }}>
            <Toolbar />
            {/* Menüye göre dinamik içerik */}
            {selectedMenu === "Kullanıcı Raporları" && (
              <Typography>
                <UserDetailReports />
              </Typography>
            )}
            {selectedMenu === "Shop Raporları" && (
              <Typography>
                {user.Admin === 1 || user.Username === "osman kale" ? (
                  <ShopData />
                ) : (
                  "Yetkiniz yok!"
                )}
              </Typography>
            )}
            {/* Diğer menü öğelerine içerik eklenebilir */}
            {selectedMenu === "Karşılaştırmalı Raporlar" && (
              <Typography>
                {user.Admin === 1 ? <CompareShops /> : "Yetkiniz yok!"}
              </Typography>
            )}
            {selectedMenu === "Kullanıcı Ücretleri" && (
              <Typography>
                {user.Admin === 1 || user.Username === "osman kale" ? (
                  <UserEarningsScreen />
                ) : (
                  "Yetkiniz yok!"
                )}
              </Typography>
            )}
            {selectedMenu === "Kullanıcı Takip" && (
              <Typography>
                {user.Admin === 1 || user.Username === "osman kale" ? (
                  <CustomScreenShotControl />
                ) : (
                  "Yetkiniz yok!"
                )}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default UserReports;
