import React, { useState, useEffect } from "react";
import "./CSS/App.css";
import { Box, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { Alert, Snackbar } from '@mui/material';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useUser } from "./Context/UserContext";

const LoginScreen = ({ onLogin }) => {

  const { setUser } = useUser();
  const [currentView, setCurrentView] = useState("login"); // "login" veya "register" olabilir
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true); // Görünürlük state
  const [showPassword, setShowPassword] = useState(false);
  let status = "1";
  let path =
    status === "0" ? "http://localhost:3003" : "https://outlettee.com:3003";
    const [alertOpen, setAlertOpen] = useState(false);
const [alertMessage, setAlertMessage] = useState('');
const [alertSeverity, setAlertSeverity] = useState('success');
const navigate = useNavigate(); 
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev); // Şifreyi göster/gizle durumunu tersine çevirir
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault(); // Mouse basılı tutulduğunda tarayıcının varsayılan davranışını engeller
  };

  const handleMouseUpPassword = () => {
    // Şifreyi gizlemek için bir işlem yapmıyoruz, burada sadece örnek olarak yer alıyor
  };
  const CustomButton = styled(Button)({
    borderColor: "black",
    color: "black",
    border: "2px solid #1A1A1A",
    borderRadius: "15px",
    boxSizing: "border-box",
    fontFamily:
      'Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: "16px",
    fontWeight: 600,
    minHeight: "30px", // Increased to ensure consistent height
    padding: "16px 24px",
    transition: "all 300ms cubic-bezier(.23, 1, 0.32, 1)",
    width: "100%",
    marginBottom: "16px", // Increased margin
    "&:hover": {
      color: "#fff",
      backgroundColor: "#1A1A1A",
      borderColor: "rgba(121, 147, 81)",
      boxShadow: "rgba(0, 0, 0, 0.25) 0 8px 15px",
      transform: "translateY(-2px)",
    },
    "&:active": {
      boxShadow: "none",
      transform: "translateY(0)",
    },
    "&:disabled": {
      pointerEvents: "none",
    },
  });

  const imageList = [
    "/Images/Background.png",
    "/Images/Shops/Nile/DesignCluster1/Design4/Design4.svg",
    "/Images/Shops/Nile/DesignCluster1/Design5/Design5.svg",
    "/Images/Shops/Pretty/DesignCluster1/Design1/Design1.svg",
    "/Images/Shops/Nile/DesignCluster1/Design11/i.svg",
    "/Images/Shops/Pretty/DesignCluster1/Design7/Design7.svg",
    "/Images/Shops/Pretty/DesignCluster1/Design10/e.svg",
    "/Images/Shops/Nile/DesignCluster1/Design9/w.svg",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false);

      const timeoutId = setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
        setIsVisible(true);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [imageList.length]);

  const handleNavigation = (id) => {
    if (id === "Converter") {
      //navigate("/converter");
    }
  };
  const handleFolderSubmit = async (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.name;
    const data = new FormData(event.target);

    if (buttonClicked === "toLogin") {
     
      try {
        const username = data.get("Username");
        const password = data.get("Password");
        const response = await axios.post(path + "/api/login", {
          username,
          password,
        });
        console.log(response.data);
        setUser({
          ID: response.data.UserID,
          Username: response.data.Username,
          Email: response.data.Email,
          Admin: response.data.isAdmin,
          DesignConverter: response.data.DesignConverter,
          DesignAdmin: response.data.DesignAdmin,
          TradeMark: response.data.TradeMark,
          UserEffort: response.data.UserEffort,
          ShopEffort: response.data.ShopEffort,
          Reports: response.data.Reports,
          ReportAdmin: response.data.ReportAdmin,
        });

        onLogin(); // Giriş durumunu App.js'e bildir
        navigate("/");
      } catch (error) {
        setAlertSeverity('error');
        if (error.response?.status === 400) {
          setAlertMessage(error.response.data.error || 'Geçersiz kullanıcı adı veya şifre!');
        } else if (error.response?.status === 500) {
          setAlertMessage('Sunucu hatası oluştu. Lütfen daha sonra tekrar deneyin.');
        } else {
          setAlertMessage('Bir hata oluştu: ' + (error.response?.data?.error || error.message));
        }
        setAlertOpen(true);
      }
     
    }
    if (buttonClicked === "Register") {
      try {
        const username = data.get("Username");
        const email = data.get("Email");
        const password = data.get("Password");
  
        const response = await axios.post(path + "/api/register", {
          username,
          email,
          password,
        });
  
        setAlertSeverity('success');
        setAlertMessage('Kayıt işlemi başarılı!');
        setAlertOpen(true);
        setTimeout(() => setCurrentView("login"), 2000);
  
      } catch (error) {
        setAlertSeverity('error');
        // Check for specific error types from backend
        if (error.response?.data?.error === 'Username already exists') {
          setAlertMessage('Bu kullanıcı adı zaten kullanılıyor!');
        } else if (error.response?.status === 400) {
          setAlertMessage(error.response.data.error || 'Geçersiz kayıt bilgileri!');
        } else if (error.response?.status === 500) {
          setAlertMessage('Sunucu hatası oluştu. Lütfen daha sonra tekrar deneyin.');
        } else {
          setAlertMessage('Bir hata oluştu: ' + (error.response?.data?.error || error.message));
        }
        setAlertOpen(true);
        console.error('Registration Error:', error.response?.data);
      }
    }
  };
  const renderContent = () => {
   
    switch (currentView) {
      case "login":
        return (
          <>     
          <form onSubmit={handleFolderSubmit}>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <AccountCircle
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  id="Username"
                  name="Username"
                  label="Kullanıcı Adı"
                  variant="standard"
                />
              </Box>

              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <KeyIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  <TextField
                    variant="standard"
                    type={showPassword ? "text" : "password"}
                    label="Şifre"
                    name="Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                  width: "100%",
                  height: "10%", // Changed from fixed height
                  gap: "1px", // Add space between buttons
                  flexDirection: "column",
                  marginTop: "16px", // Increased margin
              }}
            >
              <CustomButton
                id={"SvgAdminPanel"}
                name="toLogin"
                onClick={() => handleNavigation("SvgAdminPanel")}
                type="submit"
              >
                Üye girişi
              </CustomButton>
              <CustomButton
                name="Register"
                id={"Register"}
                onClick={() => setCurrentView("register")}
              >
                Kaydol
              </CustomButton>
            </Box>
            </form>
          </>
        );
      case "register":
        return (
          <>
            <form onSubmit={handleFolderSubmit}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <AccountCircle
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  />
                  <TextField
                    id="Username"
                    name="Username"
                    label="Kullanıcı Adı"
                    variant="standard"
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <EmailIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  <TextField
                    name="Email"
                    id="Email"
                    label="Email"
                    variant="standard"
                  />
                </Box>
                <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <KeyIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                    <TextField
                      variant="standard"
                      type={showPassword ? "text" : "password"}
                      name="Password"
                      label="Şifre"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={
                                showPassword
                                  ? "hide the password"
                                  : "display the password"
                              }
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "10%", // Changed from fixed height
                  gap: "1px", // Add space between buttons
                  flexDirection: "column",
                  marginTop: "16px", // Increased margin
                }}
              >
                <CustomButton
                  id="toLogin"
                  name="toLogin"
                  onClick={() => setCurrentView("login")}
                >
                  Giriş Yap
                </CustomButton>

                <CustomButton id="register" type="submit" name="Register">
                  Kayıt Ol
                </CustomButton>
              </Box>
            </form>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <> 
     <Snackbar 
        open={alertOpen} 
        autoHideDuration={6000} 
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setAlertOpen(false)} 
          severity={alertSeverity}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

    <div className="OuterContainer">
      <div className="MenuContainer">
        <img src="/Logo.png" alt="Logo" className="logoImage" />
        {renderContent()}
      </div>
      <div className="Divider"></div>
      <div className="LogoContainer">
        <img
          src={imageList[currentImageIndex]}
          alt="Logo"
          className={`LoopImage ${isVisible ? "visible" : ""}`}
        />
      </div>
    </div>
     </>
  );
};

export default LoginScreen;
